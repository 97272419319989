import $ from 'jquery';
import {ENDERECO_API} from '../../config/constant';
import {decode as base64_decode, encode as base64_encode} from 'base-64';

const RestAplicativosGet = (config) => {
  const caminho = config.dest.trim();
  const usersRt = base64_decode(sessionStorage.getItem(`ddesp`));
  const storage = JSON.parse(usersRt);
  const emp = storage[0];
  const uid = storage[3];
  if (caminho === 'cards') {
    return new Promise(function (resolve, reject) {
      try {
        const dataPost_ = [base64_encode(emp), base64_encode(uid)];
        $.ajax({
          url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
          type: 'Get',
          dataType: 'json',
          data: {
            pp: base64_encode('aplicativos'),
            ll: base64_encode('cards'),
            dd: base64_encode(JSON.stringify(dataPost_)),
          },
          success: async function (data, status, xhr) {
            data = JSON.parse(base64_decode(data));
            resolve([200, data]);
          },
          error: function (xhr, status, error) {
            reject([400, {message: 'erro'}]);
          },
        });
      } catch (err) {
        reject([500, {message: 'Server Error'}]);
      }
    });
  } else if (caminho === 'aplicativos') {
    return new Promise(function (resolve, reject) {
      try {
        const dataPost_ = [base64_encode(emp), base64_encode(uid)];
        $.ajax({
          url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
          type: 'Get',
          dataType: 'json',
          data: {
            pp: base64_encode('aplicativos'),
            ll: base64_encode('aplicativos'),
            dd: base64_encode(JSON.stringify(dataPost_)),
          },
          success: async function (data, status, xhr) {
            data = JSON.parse(base64_decode(data));
            resolve([200, data]);
          },
          error: function (xhr, status, error) {
            reject([400, {message: 'erro'}]);
          },
        });
      } catch (err) {
        reject([500, {message: 'Server Error'}]);
      }
    });
  } else if (caminho === 'clientesPlanos') {
    return new Promise(function (resolve, reject) {
      try {
        const dataPost_ = [base64_encode(emp), base64_encode(uid)];
        $.ajax({
          url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
          type: 'Get',
          dataType: 'json',
          data: {
            pp: base64_encode('aplicativos'),
            ll: base64_encode('clientesPlanos'),
            dd: base64_encode(JSON.stringify(dataPost_)),
          },
          success: async function (data, status, xhr) {
            data = JSON.parse(base64_decode(data));
            resolve([200, data]);
          },
          error: function (xhr, status, error) {
            reject([400, {message: 'erro'}]);
          },
        });
      } catch (err) {
        reject([500, {message: 'Server Error'}]);
      }
    });
  } else if (caminho === 'getPlanos') {
    return new Promise(function (resolve, reject) {
      try {
        const dataPost_ = [base64_encode(emp), base64_encode(uid)];
        $.ajax({
          url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
          type: 'Get',
          dataType: 'json',
          data: {
            pp: base64_encode('aplicativos'),
            ll: base64_encode('getPlanos'),
            dd: base64_encode(JSON.stringify(dataPost_)),
          },
          success: async function (data, status, xhr) {
            data = JSON.parse(base64_decode(data));
            resolve([200, data]);
          },
          error: function (xhr, status, error) {
            const data = xhr.responseText;

            reject([400, {message: data}]);
          },
        });
      } catch (err) {
        reject([500, {message: 'Server Error'}]);
      }
    });
  } else if (caminho === 'getPlanosLoja') {
    return new Promise(function (resolve, reject) {
      try {
        const dataPost_ = [base64_encode(emp), base64_encode(uid)];
        $.ajax({
          url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
          type: 'Get',
          dataType: 'json',
          data: {
            pp: base64_encode('aplicativos'),
            ll: base64_encode('getPlanosLoja'),
            dd: base64_encode(JSON.stringify(dataPost_)),
          },
          success: async function (data, status, xhr) {
            data = JSON.parse(base64_decode(data));
            resolve([200, data]);
          },
          error: function (xhr, status, error) {
            const data = xhr.responseText;

            reject([400, {message: data}]);
          },
        });
      } catch (err) {
        reject([500, {message: 'Server Error'}]);
      }
    });
  } else if (caminho === 'getClientesPlanos') {
   
    
    return new Promise(function (resolve, reject) {
      try {
        const dataPost_ = [base64_encode(emp), base64_encode(uid)];
        $.ajax({
          url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
          type: 'Get',
          dataType: 'json',
          data: {
            pp: base64_encode('aplicativos'),
            ll: base64_encode('getClientesPlanos'),
            dd: base64_encode(JSON.stringify(dataPost_)),
          },
          success: async function (data, status, xhr) {
            data = JSON.parse(base64_decode(data));
            resolve([200, data]);
          },
          error: function (xhr, status, error) {
            const data = xhr.responseText;

            reject([400, {message: data}]);
          },
        });
      } catch (err) {
        reject([500, {message: 'Server Error'}]);
      }
    });
  } else if (caminho === 'getAplicativosById') {
   
    
    return new Promise(function (resolve, reject) {
      try {
        const dataPost_ = [base64_encode(emp), base64_encode(uid), base64_encode(config.cod)];
       
        
        $.ajax({
          url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
          type: 'Get',
          dataType: 'json',
          data: {
            pp: base64_encode('aplicativos'),
            ll: base64_encode('getAplicativosById'),
            dd: base64_encode(JSON.stringify(dataPost_)),
          },
          success: async function (data, status, xhr) {
            data = base64_decode(data);
            resolve([200, data]);
          },
          error: function (xhr, status, error) {
            const data = xhr.responseText;

            reject([400, {message: data}]);
          },
        });
      } catch (err) {
        reject([500, {message: 'Server Error'}]);
      }
    });
  } else if (caminho === 'getValorUpgradePlano') {
    return new Promise(function (resolve, reject) {
      try {
        const dataPost_ = [base64_encode(emp), base64_encode(uid), config.dataPost];
        $.ajax({
          url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
          type: 'Get',
          dataType: 'json',
          data: {
            pp: base64_encode('aplicativos'),
            ll: base64_encode('getValorUpgradePlano'),
            dd: dataPost_,
          },
          success: async function (data, status, xhr) {
            resolve([200, data]);
          },
          error: function (xhr, status, error) {
            const data = xhr.responseText;

            reject([400, {message: data}]);
          },
        });
      } catch (err) {
        reject([500, {message: 'Server Error'}]);
      }
    });
  }
  // else if (caminho === 'getTotalEmpUserContratados') {
  //   return new Promise(function (resolve, reject) {
  //     try {
  //       const dataPost_ = [base64_encode(emp), base64_encode(uid)];
  //       $.ajax({
  //         url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
  //         type: 'Get',
  //         dataType: 'json',
  //         data: {
  //           pp: base64_encode('aplicativos'),
  //           ll: base64_encode('getTotalEmpUserContratados'),
  //           dd: base64_encode(JSON.stringify(dataPost_)),
  //         },
  //         success: async function (data, status, xhr) {
  //           data = JSON.parse(base64_decode(data));
  //           resolve([200, data]);
  //         },
  //         error: function (xhr, status, error) {
  //           const data = xhr.responseText;

  //           reject([400, {message: data}]);
  //         },
  //       });
  //     } catch (err) {
  //       reject([500, {message: 'Server Error'}]);
  //     }
  //   });
  // }
};

const RestAplicativosPost = (config) => {
  const usersRt = base64_decode(sessionStorage.getItem(`ddesp`));
  const storage = JSON.parse(usersRt);
  const emp = storage[0];
  const uid = storage[3];

  const Arr_ = JSON.parse(config.data);
  const caminho = Arr_.dest.trim();
  if (caminho === 'dadosDeContratoDemaisApps') {
    return new Promise(function (resolve, reject) {
      const {dataPost} = JSON.parse(config.data);
      try {
        const dataPost_ = [base64_encode(emp), base64_encode(uid), dataPost];
        $.ajax({
          url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
          type: 'Post',
          dataType: 'json',
          data: {
            pp: base64_encode('aplicativos'),
            ll: base64_encode('dadosDeContratoDemaisApps'),
            dd: dataPost_,
          },
          success: async function (data, status, xhr) {
            data = base64_decode(data);
            resolve([200, data]);
          },
          error: function (xhr, status, error) {
            reject([400, {message: 'erro'}]);
          },
        });
      } catch (err) {
        reject([500, {message: err + ' Server Error'}]);
      }
    });
  } else if (caminho === 'dadosDeContratoEmpAdicionais'){
  
    return new Promise(function (resolve, reject) {
      const {dataPost} = JSON.parse(config.data);
      try {
        const dataPost_ = [base64_encode(emp), base64_encode(uid), base64_encode(JSON.stringify(dataPost))];
        $.ajax({
          url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
          type: 'Post',
          dataType: 'json',
          data: {
            pp: base64_encode('aplicativos'),
            ll: base64_encode('dadosDeContratoEmpAdicionais'),
            dd: base64_encode(JSON.stringify(dataPost_)),
          },
          success: async function (data, status, xhr) {
            data = base64_decode(data);
            resolve([200, data]);
          },
          error: function (xhr, status, error) {
            reject([400, {message: 'erro'}]);
          },
        });
      } catch (err) {
        reject([500, {message: err + ' Server Error'}]);
      }
    });
  } else if (caminho === 'dadosDeContratoUsersAdicionais') {
    return new Promise(function (resolve, reject) {
      const {dataPost} = JSON.parse(config.data);
      try {
        const dataPost_ = [base64_encode(emp), base64_encode(uid), base64_encode(JSON.stringify(dataPost))];
        $.ajax({
          url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
          type: 'Post',
          dataType: 'json',
          data: {
            pp: base64_encode('aplicativos'),
            ll: base64_encode('dadosDeContratoUsersAdicionais'),
            dd: base64_encode(JSON.stringify(dataPost_)),
          },
          success: async function (data, status, xhr) {
            data = base64_decode(data);
            resolve([200, data]);
          },
          error: function (xhr, status, error) {
            reject([400, {message: 'erro'}]);
          },
        });
      } catch (err) {
        reject([500, {message: err + ' Server Error'}]);
      }
    });
  } else if (caminho === 'iniciarTeste') {
    return new Promise(function (resolve, reject) {
      const {dataPost} = JSON.parse(config.data);
      const dataPost_ = [base64_encode(emp), base64_encode(uid), base64_encode(JSON.stringify(dataPost))];
      try {
        $.ajax({
          url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
          type: 'Get',
          dataType: 'json',
          data: {
            pp: base64_encode('aplicativos'),
            ll: base64_encode('iniciarTeste'),
            dd: base64_encode(JSON.stringify(dataPost_)),
          },
          success: async function (data, status, xhr) {
            data = base64_decode(data);
            resolve([200, data]);
          },
          error: function (xhr, status, error) {
            reject([400, {message: 'erro'}]);
          },
        });
      } catch (err) {
        reject([500, {message: err + ' Server Error'}]);
      }
    });
  } else if (caminho === 'contratarPlano') {
    return new Promise(function (resolve, reject) {
      const {dataPost} = JSON.parse(config.data);
      dataPost.push(storage[6]);
      const dataPost_ = [base64_encode(emp), base64_encode(uid), dataPost];
      try {
        $.ajax({
          url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
          type: 'Post',
          dataType: 'json',
          data: {
            pp: base64_encode('aplicativos'),
            ll: base64_encode('contratarPlano'),
            dd: dataPost_,
          },
          success: async function (data, status, xhr) {
             resolve([200, data]);
          },
          error: function (xhr, status, error) {
            reject([400, {message: 'erro'}]);
          },
        });
      } catch (err) {
        reject([500, {message: err + ' Server Error'}]);
      }
    });
  } else if (caminho === 'realizarUpgradePlano') {
    return new Promise(function (resolve, reject) {
      const {dataPost} = JSON.parse(config.data);
      const dataPost_ = [base64_encode(emp), base64_encode(uid), dataPost];
      try {
        $.ajax({
          url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
          type: 'Post',
          dataType: 'json',
          data: {
            pp: base64_encode('aplicativos'),
            ll: base64_encode('realizarUpgradePlano'),
            dd: dataPost_,
          },
          success: async function (data, status, xhr) {
            resolve([200, data]);
          },
          error: function (xhr, status, error) {
            reject([400, {message: 'erro'}]);
          },
        });
      } catch (err) {
        reject([500, {message: err + ' Server Error'}]);
      }
    });
  }
};
export {RestAplicativosGet, RestAplicativosPost};
