import React, { useState, useEffect, useContext } from 'react';
import { Button, Modal, Row, Col, Form, InputGroup, ButtonGroup } from 'react-bootstrap';
import axios from '../../services/axios';
import { Tooltip } from '@mui/material';
import toast from 'react-hot-toast';
import LogoIntegracao from '../../assets/images/canvas/api.png';
import { decode as base64_decode } from 'base-64';
import Swal from 'sweetalert2';
import { ConfigContext } from '../../contexts/ConfigContext';

const ModalIntegracoes = ({ showModalIntegracoes, setShowModalIntegracoes }) => {
  const { config } = useContext(ConfigContext);
  const [tokenUser, setTokenUser] = useState('');
  const [tokenAccount, setTokenAccount] = useState('');
  const [loading, setLoading] = useState(true);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const storageData = sessionStorage.getItem('ddesp');
  const localStorageData = storageData ? JSON.parse(base64_decode(storageData)) : null;

  useEffect(() => {
    if (localStorageData) {
      if (localStorageData[23] === 't') {
        setIsAuthorized(true); // Caso haja dados no localStorage, considera autorizado
        setIsLoading(false);
      } else {
        setIsAuthorized(false); // Caso haja dados no localStorage, considera não autorizado
        setIsLoading(false);
      }

    } else {
      setIsAuthorized(false); // Caso não haja dados no localStorage, considera não autorizado
      setIsLoading(false);
    }
  }, [localStorageData]);



  useEffect(() => {
    const fetchTokens = async () => {
      try {
        setLoading(true);
        const usersRt = base64_decode(sessionStorage.getItem('ddesp'));
        const users = JSON.parse(usersRt);
        const dataPost = [users[7]];

        const response = await axios.get('/api/users/', { dest: 'getkApi', dataPost });
        const [accountToken, userToken] = response.data;

        setTokenAccount(accountToken);
        setTokenUser(userToken);
      } catch (error) {
        console.error('Erro ao obter tokens:', error);
        toast.error('Erro ao obter tokens.');
      } finally {
        setLoading(false);
      }
    };

    fetchTokens();
  }, []);

  const handleTokenReset = () => {
    setShowModalIntegracoes(false);

    Swal.fire({
      title: 'Tem certeza?',
      text: 'Você deseja realmente redefinir o token do usuário?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#4099ff',
      cancelButtonColor: '#6c757d',
      confirmButtonText: 'Sim, redefinir',
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        axios.get('/api/users/', { dest: 'geratkApiUs' })
          .then((response) => {
            setTokenUser(response.data);
            toast.success('Token redefinido com sucesso!');
          })
          .catch((error) => {
            console.error('Erro ao redefinir token:', error);
            toast.error('Erro ao redefinir token.');
          })
          .finally(() => {
            setTimeout(() => {
              setShowModalIntegracoes(true);
            }, 2000);
          });
      } else {
        setShowModalIntegracoes(true);
      }
    });
  };

  const handleCopyToClipboard = (tokenToCopy) => {
    if (!tokenToCopy) {
      toast.warning('Nenhum token disponível para copiar.', { position: 'top-center' });
      return;
    }

    navigator.clipboard.writeText(tokenToCopy)
      .then(() => toast.success('Token copiado para a área de transferência!', { position: 'top-center' }))
      .catch((err) => toast.error('Erro ao copiar token para a área de transferência.', err, { position: 'top-center' }));
  };



  return (
    <Modal
      id='modalIntegracoes'
      size='lg'
      show={showModalIntegracoes}
      onShow={() => setIsLoading(false)}
      onHide={() => setShowModalIntegracoes(false)}
    >
      <Modal.Header closeButton className='border-0'>
      </Modal.Header>
      <Modal.Body>
        {!isLoading ? (
          <>
            <div className='text-center'>
              <img
                src={LogoIntegracao}
                width={150}
                alt='Imagem com uma nuvem que faz conexões'
                className='mb-2'
              />
              <h5 className='mb-3'>{isAuthorized === !true ? 'Dados para integração - API RESTful' : 'API RESTful'}</h5>
              <p className='text-left'>
                Receba facilmente os Documentos Fiscais diretamente em seu software, sem se preocupar com as frequentes alterações nas regras da SEFAZ.
              </p>
              {isAuthorized === true &&
                <>
                  <p className='text-left'>
                    Para configurar a integração, utilize os dados fornecidos abaixo nos campos indicados no <a href="https://api.espiaonfe.com.br/swagger/index.html" target='_blank'>manual de integração.</a>
                  </p>
                  <hr />
                </>
              }

            </div>

            {isAuthorized === false ? (
              <Row className='text-center mt-4 bg-light p-3'>
                <Col>
                  <h5 className='text-muted m-0'>Faça um upgrade de plano para utilizar nossa API.</h5>
                </Col>
              </Row>
            )
              :
              (
                <>
                  <Row>
                    <Col sm={8}>
                      <Form.Group controlId='formPlaintextName1' className='mb-4'>
                        <Form.Label><span className='mdi mdi-key mr-2'></span>Token da conta</Form.Label>
                        <InputGroup>
                          <Form.Control readOnly value={loading ? 'Carregando...' : tokenAccount} />
                          <InputGroup.Append>
                            <Tooltip title='Copiar token da conta' arrow>
                              <Button
                                id='btnCopyTkAccount'
                                variant='primary'
                                disabled={loading}
                                onClick={() => handleCopyToClipboard(tokenAccount)}
                              >
                                <span className='mdi mdi-content-copy'></span>
                              </Button>
                            </Tooltip>
                          </InputGroup.Append>
                        </InputGroup>
                      </Form.Group>

                      <Form.Group controlId='formPlaintextName2' className='mb-4'>
                        <Form.Label><span className='mdi mdi-account-key mr-2'></span>Token do usuário</Form.Label>
                        <InputGroup>
                          <Form.Control readOnly value={loading ? 'Carregando...' : tokenUser} />
                          <InputGroup.Append>
                            <ButtonGroup>
                              <Tooltip title='Redefinir Token do usuário' arrow>
                                <Button
                                  variant='success'
                                  onClick={handleTokenReset}
                                  disabled={loading}
                                >
                                  <span className='mdi mdi-refresh'></span>
                                </Button>
                              </Tooltip>
                              <Tooltip title='Copiar token do usuário' arrow>
                                <Button
                                  id='btnCopyTkUser'
                                  variant='primary'
                                  disabled={loading}
                                  onClick={() => handleCopyToClipboard(tokenUser)}
                                >
                                  <span className='mdi mdi-content-copy'></span>
                                </Button>
                              </Tooltip>
                            </ButtonGroup>
                          </InputGroup.Append>
                        </InputGroup>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className='mt-4'>
                    <Col sm={12} className='text-center'>
                      <Button 
                      size='sm' 
                      variant='primary' 
                      className='rounded'
                      onClick={() => window.open('https://espiao-cloud.tawk.help/article/api-restful-iniciando-os-testes', '_blank')}
                      >
                        API RESTful: Iniciando os testes
                        </Button>
                    </Col>
                  </Row>
                </>
              )}
          </>
        ) : (
          <Col sm={12} className='text-center'>
            <p>Carregando...</p>
            <span className='fa fa-spinner fa-spin f-40'></span>
          </Col>
        )}
      </Modal.Body>
      {!isLoading && isAuthorized === false && (
        <Modal.Footer>
          <Button
            variant='primary'
            size='sm'
            className='rounded'
            onClick={() => window.open('https://espiao-cloud.tawk.help/article/api-restful-integre-seu-software-com-o-espi%C3%A3o-cloud', '_blank')}
          >
            Saiba mais
          </Button>
          <Button
            variant='success'
            size='sm'
            className='rounded'
            onClick={() => window.location.href = '/planos'}
          >
            Fazer upgrade
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default ModalIntegracoes;
