import $ from 'jquery';
import {ENDERECO_API} from '../../config/constant';
import {decode as base64_decode} from 'base-64';
// const usersRt = sessionStorage.getItem(`ddesp`);
// const users = usersRt !== null ? JSON.parse(base64_decode(usersRt)) : null;

const RestFaturasGet = (config) => {
  const usersRt = sessionStorage.getItem(`ddesp`);
  const users = usersRt !== null ? JSON.parse(base64_decode(usersRt)) : null;

  const caminho = config.dest.trim();
  const emp = users[0];
  const user = users[3];

  if (caminho === 'getFaturas') {
    return new Promise(function (resolve, reject) {
      try {
        $.ajax({
          url: `${ENDERECO_API}src/api/controller/faturas_controller.class.php`,
          type: 'Get',
          dataType: 'json',
          data: {
            act: 'getFaturas',
            key: emp,
            ukey: user,
          },
          success: async function (data, status, xhr) {
            resolve([200, data]);
          },
          error: function (xhr, status, error) {
            reject([400, {message: 'erro'}]);
          },
        });
      } catch (err) {
        reject([500, {message: err}]);
      }
    });
  }
};
export default RestFaturasGet;
