import {Card, Form} from 'react-bootstrap';
import {Tooltip} from '@mui/material';

const OpcoesNfe = ({rbManif, setRbManif, showOptConsulta, arrStrg, empselecionada, setRbPeriodo, rbNsu, setRbNsu, txtNsuValue, setTxtNsuValue, handleKeyUpNSU, setAlertNfe}) => {
 
  return (
    <>
      <Card className='border mt-3 mb-1'>
        <Card.Body className='pt-2 pb-2'>
          <strong>Manifestação do destinatário</strong>
          <Tooltip
            title='Saiba mais sobre o processo de manifestação'
            arrow
            placement='top'
          >
            <a
              href='https://www.espiaonfe.com.br/blog/manifestacao-do-destinatario-o-que-e-como-funciona-este-evento/'
              target='_blank'
            >
              <span className='mdi mdi-information ml-1 f-12 text-primary pointer'></span>
            </a>
          </Tooltip>

          <Form.Group className='mt-2 mb-0'>
            <Form.Check
              inline
              custom
              checked={rbManif === '0'}
              value={0}
              className='rb-w w-100'
              style={{zIndex: 0}}
              type='radio'
              onChange={(e) => setRbManif(e.target.value)}
              label='Não manifestar'
              name='radioManif'
              id='radioNaoManif'
            />
            <Form.Check
              inline
              custom
              checked={rbManif === '1'}
              value={1}
              className='rb-w w-100'
              style={{zIndex: 0}}
              type='radio'
              onChange={(e) => setRbManif(e.target.value)}
              label='Ciência da Operação (Recebimento do XML)'
              name='radioManif'
              id='radioSimManif'
            />
          </Form.Group>
        </Card.Body>
      </Card>

      {showOptConsulta === true && (
        <>
          {arrStrg['possuiNotas'] === false && (
            <Card className='border mb-1'>
              <Card.Body className='pt-2 pb-2'>
                <strong>Período da consulta</strong>

                <Form.Group className='mt-2 mb-0'>
                  <Form.Check
                    inline
                    custom
                    defaultChecked={true}
                    value={90}
                    className='rb-w w-100'
                    style={{zIndex: 0}}
                    type='radio'
                    onChange={(e) => setRbPeriodo(e.target.value)}
                    label='Últimos 90 dias'
                    name='radioPeriodo'
                    id='radioPeriodo1'
                  />
                  <Form.Check
                    inline
                    custom
                    value={60}
                    className='rb-w w-100'
                    style={{zIndex: 0}}
                    type='radio'
                    onChange={(e) => setRbPeriodo(e.target.value)}
                    label='Últimos 60 dias'
                    name='radioPeriodo'
                    id='radioPeriodo2'
                  />
                  <Form.Check
                    inline
                    custom
                    value={30}
                    className='rb-w w-100'
                    style={{zIndex: 0}}
                    type='radio'
                    onChange={(e) => setRbPeriodo(e.target.value)}
                    label='Últimos 30 dias'
                    name='radioPeriodo'
                    id='radioPeriodo3'
                  />
                </Form.Group>
              </Card.Body>
            </Card>
          )}
          {arrStrg['possuiNotas'] === true && (
            <Card className='border mb-2'>
              <Card.Body className='pt-2'>
                <strong>NSU</strong>

                <Form.Group className='mt-2 mb-0'>
                  <Form.Check
                    inline
                    custom
                    defaultChecked={true}
                    value={0}
                    className='rb-w w-100'
                    style={{zIndex: 0}}
                    type='radio'
                    onChange={(e) => setRbNsu(e.target.value)}
                    label={`Último NSU${empselecionada.maior_nsu_nfe !== null ? ': ' + empselecionada.maior_nsu_nfe : ''} (recomendado) `}
                    name='radioNsu'
                    id='radioNSU1'
                  />
                  <Form.Check
                    inline
                    custom
                    value={1}
                    className='rb-w w-100'
                    style={{zIndex: 0}}
                    type='radio'
                    onChange={(e) => {
                      if (e.target.checked === true) {
                        setTimeout(() => {
                          const txt = document.getElementById('txtNsu');
                          txt.focus();
                        }, 300);
                      }
                      setRbNsu(e.target.value);
                    }}
                    label='A partir do NSU'
                    name='radioNsu'
                    id='radioNSU2'
                  />
                  {rbNsu === '1' && (
                    <Form.Control
                      type='text'
                      placeholder='Digite o NSU'
                      min={10}
                      onKeyUp={handleKeyUpNSU}
                      value={txtNsuValue}
                      id='txtNsu'
                      onChange={(e) => setTxtNsuValue(e.target.value)}
                      className='mt-3 col-6'
                      style={{zIndex: 0}}
                    />
                  )}
                </Form.Group>
              </Card.Body>
            </Card>
          )}
        </>
      )}

     {setAlertNfe()}
      {/* <div className='p-1'>
        <p className='mb-0'>
          <span className='mdi mdi-alert text-warning mr-1 f-16'></span>
          <strong>Aviso</strong>
        </p>
        <small className='p-0'>
          A SEFAZ pode não liberar os documentos de períodos anteriores.
          <a href='https://www.espiaonfe.com.br/lab/quais-sao-as-regras-para-consultar-nfe-na-sefaz/' target='_blank'> Saiba mais</a>.{' '}
        </small>
      </div> */}
    </>
  );
};

export default OpcoesNfe;
