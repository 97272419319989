export const BOX_LAYOUT = 'BOX_LAYOUT';
export const CHANGE_LAYOUT = 'CHANGE_LAYOUT';
export const CHANGE_SUB_LAYOUT = 'CHANGE_SUB_LAYOUT';
export const COLLAPSE_MENU = 'COLLAPSE_MENU';
export const COLLAPSE_TOGGLE = 'COLLAPSE_TOGGLE';
export const HEADER_BACK_COLOR = 'HEADER_BACK_COLOR';
export const HEADER_FIXED_LAYOUT = 'HEADER_FIXED_LAYOUT';
export const LAYOUT_TYPE = 'LAYOUT_TYPE';
export const NAV_COLLAPSE_LEAVE = 'NAV_COLLAPSE_LEAVE';
export const NAV_CONTENT_LEAVE = 'NAV_CONTENT_LEAVE';
export const NAV_FIXED_LAYOUT = 'NAV_FIXED_LAYOUT';
export const RESET = 'RESET';
export const RTL_LAYOUT = 'RTL_LAYOUT';
export const IMGPERFIL = 'IMGPERFIL';
export const NOMEPERFIL = 'NOMEPERFIL';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const ACCOUNT_INITIALISE = 'ACCOUNT_INITIALISE';
export const FIREBASE_STATE_CHANGED = 'FIREBASE_STATE_CHANGED';
export const MENU_USUARIO = 'MENU_USUARIO';
export const WS = 'WS';
export const SENDMESSAGE = 'SENDMESSAGE';
export const CHANGEDDATAUSER = 'CHANGEDDATAUSER';
export const UPDATEDATAINI = 'UPDATEDATAINI';
export const POSSUI_EMPRESAS = 'POSSUI_EMPRESAS';
export const PRIMEIRO_ACESSO = 'PRIMEIRO_ACESSO';
export const TIPOTESTE = 'TIPOTESTE';
export const POSSUI_NOTAS = 'POSSUI_NOTAS';
export const ESPIONAR_SEFAZ = 'ESPIONAR_SEFAZ';
export const USER64 = 'USER64';
export const TOGGLE_BUTTON_TEXT = 'TOGGLE_BUTTON_TEXT';
export const TIPOCONSULTA = 'TIPOCONSULTA';
