import React from 'react';
import {Redirect, useHistory} from 'react-router-dom';
import useAuth from '../../hooks/useAuth';

import {decode as base64_decode, encode as base64_encode} from 'base-64';
import {ENDERECO_API} from '../../config/constant';

const AuthGuard = ({children}) => {
  const history = useHistory();
  const {isLoggedIn} = useAuth();
  if (!isLoggedIn) {
    return <Redirect to='/auth/login' />;
  } else {
    const sessStorage = JSON.parse(base64_decode(sessionStorage.getItem('ddesp')));
    let link_ = window.location.href.replace(ENDERECO_API, '');

    if (link_.includes('localhost/')) {
      link_ = '/' + link_.replace('http://localhost:3000/', '');
    } else if (link_.includes('192.168.0.192')) {
      link_ = '/' + link_.replace('http://192.168.0.192:3000/', '');
    }

    let possuiAcesso = false;

    if (link_.includes('inicio/') || link_.includes('dashboard') || link_.includes('editarPerfil') || link_.includes('403') || link_.includes('aplicativo') || link_.includes('meuPlano') || link_.includes('planos')) {
      possuiAcesso = true;
    } else {
      const sessStorageRaw = sessionStorage.getItem('ddesp');
      //

      if (!sessStorageRaw) {
        return <Redirect to='/auth/login' />;
      }

      const sessStorage = JSON.parse(base64_decode(sessStorageRaw));

      let link_ = window.location.href.replace(ENDERECO_API, '');
      link_ = '/' + link_.replace('http://localhost:3000/', '');

      // Remover caracteres caso existam
      if (link_.includes('#') || link_.includes('!')) {
        link_ = link_.split('#')[0];
        link_ = link_.split('!')[0];
      }

      if (!sessStorage[10] || !sessStorage[10][0]) {
        console.error('Unexpected sessStorage structure:', sessStorage);
        return <Redirect to='/auth/404' />;
      }

      const menus = sessStorage[10];
      const items = menus[0].items;

      let possuiAcesso = false;
      menus[0]['items'].forEach((itemP) => {
        if (itemP.children) {
          const findNfe = itemP.children.find((item) => item.url === link_);
          if (findNfe) {
            possuiAcesso = true;
          }
        }
      });
      //

      // if (possuiAcesso === false) {
      // history.push('/403');
      //     return <Redirect to='/auth/404' />;
      // }
    }

    return <React.Fragment>{children}</React.Fragment>;
  }
};

export default AuthGuard;
