import React, { useContext } from 'react';
import Swal from 'sweetalert2';
import axios from '../../../services/axios';
import { decode as base64_decode, encode as base64_encode } from 'base-64';
import { ENDERECO_API } from '../../../config/constant';

/* Formata CNPJ/CPF com traços e pontos */
export const FormatCnpjCpf = (value) => {
  const cnpjCpf = value.replace(/\D/g, '');

  if (cnpjCpf.length === 11) {
    return cnpjCpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4');
  }
  return cnpjCpf.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5');
};

export /**
 * Verifica a permissao do usuario
 *
 * @param {*} dataRestricoes
 * @param {*} restricao
 * @return {*}
 */
  const VerificaPermissaoUsuario = (dataRestricoes, restricao) => {
    if (Array.isArray(dataRestricoes) && dataRestricoes.length > 0) {
      let boolRestricao = false;
      dataRestricoes.forEach((item) => {
        if (item.item === restricao) {
          boolRestricao = true;
        }
      });

      if (boolRestricao === true) {
        Swal.fire({
          title: 'Acesso Negado',
          text: 'Você não possui permissão para acessar essa funcionalidade.',
          icon: 'error',
          confirmButtonText: 'OK',
          confirmButtonColor: '#4099ff',
        });
      }
      return boolRestricao;
    } else {
      return false;
    }
  };

export const SalvaDataPeriodoPadrao = (salvarData, data) => {
  const sessiosS = JSON.parse(base64_decode(sessionStorage.getItem(`ddesp`)));
  const localStorage_ = localStorage.getItem(`ddespU${base64_encode(sessiosS[3])}`);
  const localDescrip = JSON.parse(base64_decode(localStorage_));
  const usr = localDescrip[1];
  if (salvarData) {
    const periodo_ = data;
    localStorage.setItem(`periodoDoc${usr}`, JSON.stringify(periodo_));
  } else {
    localStorage.removeItem(`periodoDoc${usr}`);
  }
};

export const TrataDataIniEmpresas = (dados) => {
  let empSelecionadaCOokie = localStorage.getItem('empDoc');
  let empselecionada = dados[0];

  if (empSelecionadaCOokie !== undefined && empSelecionadaCOokie !== null) {
    empSelecionadaCOokie = JSON.parse(base64_decode(empSelecionadaCOokie));

    const filtered = dados.filter((obj) => {
      return obj.value === empSelecionadaCOokie.value;
    });
    if (filtered.length > 0) {
      empselecionada = filtered[0];
    }
  }
  return empselecionada;
};

export const CheckDataUser = async () => {
  return new Promise(function (resolve, reject) {
    axios.get('/api/account/', { dest: 'checkData' }).then((res) => {
      const usersRt = sessionStorage.getItem(`ddesp`) !== null ? JSON.parse(base64_decode(sessionStorage.getItem(`ddesp`))) : null;
      const response = res.data;
      // const possuiEmpresas = response.possuiEmpresas; //ficticio = dados Fic. | real = dados reais | false = sem dados
      // const possuiCert = response.possuiCert; //cert = possui certificado | no_cert  = não possui certificado
      // const possuiNotas = response.possuiNotas; //true = possui notas | false = não possui notas
      // const primeiroAcesso = response.primeiroAcesso; //true = primeiro acesso | false = não é primeiro acesso

      usersRt[11][0] = res.data;
      sessionStorage.setItem('ddesp', base64_encode(JSON.stringify(usersRt)));

      resolve(res.data);
    });
  });
};

//Função para baixar arquivo zip do instalador Cloud Monitor
export const DownloadCloudMonitor = () => {
  //Acessar pasta cloud_monitor e baixar o arquivo zip
  window.open(`${ENDERECO_API}download/CloudMonitor_Instalador.zip`, '_blank');

}


//Função que recarrega o sessionStorage
export const ReloadSessionStorage = async () => {
  const sessionStorage_ = JSON.parse(base64_decode(sessionStorage.getItem(`ddesp`)));


  const usuarioId = sessionStorage_[3];
  const usuarioNome = sessionStorage_[4];
  const usuarioEmail = sessionStorage_[6];
  const varias = sessionStorage_[18];
  const empresaId = sessionStorage_[0];

  const data = [
    usuarioId,
    usuarioNome,
    usuarioEmail,
    varias,
    empresaId,
  ];



  try {
    const res = await axios.post('/api/initData/', { dest: 'recarregaSession', data: data });


    const response = res.data;


  } catch (error) {
    console.error('Erro ao recarregar sessionStorage:', error);

  }
};

//Função para remover acentos e caracteres especiais

export const RemoverAcentosECaracteresEspeciais = (valor) => {
  return valor
    .normalize('NFD') // Normaliza o valor em forma de decomposição (NFD)
    .replace(/[\u0300-\u036f]/g, '') // Remove acentos
    .replace(/[^a-zA-Z0-9\s]/g, ''); // Permite letras, números e espaços
}



