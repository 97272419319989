import {useState, useEffect} from 'react';
import {Modal, Button, Form, Row, Col} from 'react-bootstrap';
import Select from 'react-select';
import {Rate} from 'rsuite';
import axios from 'axios';
import Swal from 'sweetalert2';

function FeedbackModal({show, handleClose}) {
  const [rating, setRating] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const [feedback, setFeedback] = useState('');

  const ratingChanged = (value) => {
    setRating(value);
  };

  const handleSubjectChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  const handleFeedbackChange = (event) => {
    setFeedback(event.target.value);
  };

  const handleSubmit = () => {
    if (!selectedOption) {
      Swal.fire({
        title: 'Erro',
        text: 'Por favor, selecione um assunto.',
        icon: 'error',
        showConfirmButton: false,
        timer: 1500,
        target: document.getElementById('feedback-modal'),
      });
      return;
    }

    if (feedback.trim() === '') {
      Swal.fire({
        title: 'Erro',
        text: 'Por favor, escreva seu feedback.',
        icon: 'error',
        showConfirmButton: false,
        timer: 1500,
        target: document.getElementById('feedback-modal'),
      });
      return;
    }

    const dataPost = [selectedOption.label, rating, feedback];

    axios
      .post('/api/feedback/', {dest: 'feedback', dataPost})
      .then((response) => {})
      .catch((error) => {});

    Swal.fire({
      html: '<p class="f-16">Agradecemos pelo seu feedback.</p><p class="f-16">Sua opinião é muito importante para nós.</p>',
      icon: 'success',
      showConfirmButton: true,
      confirmButtonText: 'Fechar',
      confirmButtonColor: '#007bff',
    });

    handleClose();
  };

  useEffect(() => {
    if (show) {
      setRating(0);
      setSelectedOption(null);
      setFeedback('');
    }
  }, [show]);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      id='feedback-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title>Feedback</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Label className='mb-3'>Como foi sua experiência com o Espião Cloud?</Form.Label>
        <Form className='d-flex justify-content-center'>
          <Form.Group>
            <Rate
              color='yellow'
              size='sm'
              defaultValue={0}
              value={rating}
              onChange={ratingChanged}
              allowHalf
            />
          </Form.Group>
        </Form>
        <Row>
          <Col>
            <Form.Label>Selecione o assunto</Form.Label>
            <Select
              placeholder='Escolha o assunto...'
              options={[
                {value: '1', label: 'Sugestões'},
                {value: '2', label: 'Reclamações'},
                {value: '3', label: 'Elogios'},
                {value: '4', label: 'Outro assunto'},
              ]}
              onChange={handleSubjectChange}
            />
          </Col>
        </Row>
        <Row className='mt-4'>
          <Col>
            <Form.Label>Envie seu Feedback</Form.Label>
            <Form>
              <Form.Group controlId='exampleForm.ControlTextarea1'>
                <Form.Control
                  as='textarea'
                  rows={3}
                  value={feedback}
                  onChange={handleFeedbackChange}
                />
              </Form.Group>
            </Form>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant='secondary'
          className='btn-sm'
          onClick={handleClose}
        >
          Fechar
        </Button>
        <Button
          variant='primary'
          className='btn-sm'
          onClick={handleSubmit}
        >
          Enviar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default FeedbackModal;
