import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import ReactDOM from 'react-dom/client';

import './services/';

import { Provider } from 'react-redux';
import { ConfigProvider } from "./contexts/ConfigContext";

import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from "./store";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
//ReactDOM.render(
   <Provider store={store}>
       <ConfigProvider>
           <App />
       </ConfigProvider>
   </Provider>,

);

reportWebVitals();
