import {useEffect, useState} from 'react';
import {Card, Form, Col, Row, Button} from 'react-bootstrap';
import Select from 'react-select';
import {decode as base64_decode, encode as base64_encode} from 'base-64';
import {addDays, differenceInDays} from 'date-fns';
import {DateRangePicker} from 'rsuite';
import {preDefinedRangesNfse} from '../../../../components/Utilitarios/Arrays';
import axios from '../../../../services/axios';
import Swal from 'sweetalert2';

// [x] - na primeira carga, nao valida os dados do municipio pré selecionado
// [x] - criar um componente para coletar usuario e senha para consultas municipais
// [x] - enviar dados usuario e senha para o backend
// [x] - coletar informações do backend, sugerir na api de municipio ja trazer usuario e senha da conta ou criar um select dos usuarios e senhas e cruzar com os municipios carregados atraves do campo codigo_municipio
// [x] - criado card de alerta para municípios que possuam observações

const OpcoesNfse = ({rbNFSe, setRbNFSe, periodoNfse, setPeriodoNfse, inscricaoMunicipal, setInscricaoMunicipal, empSelecionada, setRbNsuNfse, rbNsuNfse, txtNsuValueNfse, setTxtNsuValueNfse, handleKeyUpNSUNfse, tipoServico, setTipoServico, selectedMunicipio, setSelectedMunicipio}) => {
  const sessiosS = JSON.parse(base64_decode(sessionStorage.getItem(`ddesp`)));
  const localStorage_ = localStorage.getItem(`ddespU${base64_encode(sessiosS[3])}`);
  const localDescrip = JSON.parse(base64_decode(localStorage_));
  const usr = localDescrip[1];
  const [selectMunicipios, setSelectMunicipios] = useState([]);

  const [carregandoMunicipios, setCarregandoMunicipios] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [isDisabledA3, setIsDisabledA3] = useState(false);
  const [dataSelectTipoServ, setDataSelectTipoServ] = useState([
    {value: '1', label: 'Prestados', isDisabled: false},
    {value: '2', label: 'Tomados', isDisabled: false},
  ]);
  //const [tipoServico, setTipoServico] = useState({value: '1', label: 'Prestados', isDisabled: false});

  const handleIM = (e) => {
    const im = e.target.value.replace(/[^0-9]/g, '').substring(0, 20);
    setInscricaoMunicipal(im);
  };

  const allowedMaxDays = (maxDays) => (date) => {
    if (date > addDays(new Date(), maxDays)) {
      return true;
    }
    return false;
  };

  const handleChange = (e) => {
    if (e && e[0] && e[1]) {
      const diffDays = differenceInDays(e[1], e[0]);
      if (diffDays > 30) {
        setErrorMessage('O intervalo não pode ser superior a 30 dias.');
      } else {
        setErrorMessage('');
      }
    }
    setPeriodoNfse(e);
  };

  const handleChangeTipoServ = (e) => {
    let newData = [];
    if (e.servicoPrestado === 'Não') {
      newData = [
        {value: '1', label: 'Prestados', isDisabled: true},
        {value: '2', label: 'Tomados', isDisabled: false},
      ];
    } else if (e.servicoTomado === 'Não') {
      newData = [
        {value: '1', label: 'Prestados', isDisabled: false},
        {value: '2', label: 'Tomados', isDisabled: true},
      ];
    } else {
      newData = [
        {value: '1', label: 'Prestados', isDisabled: false},
        {value: '2', label: 'Tomados', isDisabled: false},
      ];
    }

    if (tipoServico.value === '1' && e.servicoPrestado !== 'Sim') {
      setTipoServico({value: '2', label: 'Tomados'});
    } else if (tipoServico.value === '2' && e.servicoTomado !== 'Sim') {
      setTipoServico({value: '1', label: 'Prestados'});
    }

    if (e.usuario === 'Sim' || e.senha === 'Sim') {
      handleGetUserSenhaMunicipio();
    }
    setDataSelectTipoServ(newData);
  };

  const handleGetUserSenhaMunicipio = () => {
    // buscar usuario e senha do municipio

    axios.get(`/api/initData/`, {dest: 'getUsuarioSenhaMunicipio', dataPost: [selectedMunicipio.value, empSelecionada.value]}).then((response) => {
      if (response.data.length > 0) {
        const user = document.getElementById('txtUsuarioMunicipio');
        if (user) user.value = response.data[0].usuario;

        document.getElementById('txtSenhaMunicipio').value = response.data[0].senha;
      } else {
        // Swal.fire({
        //   title: 'Erro!',
        //   text: 'Usuário e senha não encontrados.',
        //   icon: 'error',
        //   confirmButtonText: 'OK',
        //   target: '.modal-body',
        // });
      }
    });
  };

  const handleSalvarUsuarioSenha = () => {
    const usuarioInput = document.getElementById('txtUsuarioMunicipio');
    const senhaInput = document.getElementById('txtSenhaMunicipio');

    
    if (usuarioInput != null || senhaInput != null) {
      const usuario = usuarioInput !== null ? usuarioInput.value : '';
      const senha = senhaInput !== null ? senhaInput.value : '';
      if (usuario.length > 0 || senha.length > 0) {
        const dataPost = [usuario, senha, selectedMunicipio.value, empSelecionada.value];

        axios.post(`/api/initData/`, {dest: 'salvarUsuarioSenhaMunicipio', dataPost}).then((response) => {
          if (response.data === 'ok') {
            Swal.fire({
              title: 'Sucesso!',
              text: `Dados salvos com sucesso.`,
              icon: 'success',
              confirmButtonText: 'OK',
              target: '.modal-body',
            });
          } else {
            Swal.fire({
              title: 'Erro!',
              text: 'Erro ao salvar usuário e senha.',
              icon: 'error',
              confirmButtonText: 'OK',
              target: '.modal-body',
            });
          }
        });
      }
      // salvar no banco
    } else {
      // mostrar mensagem de erro
      Swal.fire({
        title: 'Atenção!',
        text: 'Preencha os campos corretamente.',
        icon: 'warning',
        confirmButtonText: 'OK',
        target: '.modal-body',
      });
    }
  };

  useEffect(() => {
    const fetchMunicipios = async () => {
      try {
        setCarregandoMunicipios(true);
        const response = await axios.get(`/api/initData/`, {dest: 'getMunicipios'});

        const dadosSelect = response.data.map((item) => {
          return {
            value: item.codigoCidade,
            label: item.cidade,
            servicoPrestado: item.servicoPrestado,
            servicoTomado: item.servicoTomado,
            consultaPorFaixa: item.consultaPorFaixa,
            provedor: item.provedor,
            observacao: item.observacao,
            certificadoDigital: item.certificadoDigital,
            isDisabled: empSelecionada.tipo === '1' && item.certificadoDigital === 'Sim' ? true : false,
            usuario: item.usuario,
            senha: item.senha,
          };
        });

        // dadosSelect.unshift({
        //   value: '0',
        //   label: 'Todos os municípios',
        //   servicoPrestado: ' - ',
        //   servicoTomado: ' - ',
        //   consultaPorFaixa: '1',
        //   provedor: ' - ',
        //   certificadoDigital: '1',
        // });

        setSelectMunicipios(dadosSelect);
        if (localStorage.getItem(`munNfs-${usr}`) !== null) {
          setSelectedMunicipio(JSON.parse(localStorage.getItem(`munNfs-${usr}`)));
          handleChangeTipoServ(JSON.parse(localStorage.getItem(`munNfs-${usr}`)));
        } else {
          setSelectedMunicipio(dadosSelect[0]);
          handleChangeTipoServ(dadosSelect[0]);
        }
        setCarregandoMunicipios(false);
      } catch (error) {
        console.error(error);
      }
    };
    if (selectMunicipios.length === 0) {
      fetchMunicipios();
    } else {
      if (empSelecionada.tipo === '0') {
        if (rbNFSe !== -1) {
          setRbNFSe('0');
          setSelectedMunicipio(selectMunicipios[0]);
        }
      }
      const dadosSelect = selectMunicipios.map((item) => {
        return {
          ...item,
          isDisabled: empSelecionada.tipo === '1' && item.certificadoDigital === 'Sim' ? true : false,
        };
      });

      setSelectMunicipios(dadosSelect);
      setSelectedMunicipio(dadosSelect[0]);
      setCarregandoMunicipios(false);
    }
  }, [empSelecionada]);

  useEffect(() => {
    const savedTipoServico = localStorage.getItem(`tipoServico${usr}`);
    if (savedTipoServico) {
      setTipoServico(JSON.parse(savedTipoServico));
    }
  }, []);

  return (
    <>
      <Card className='shadow-none border mt-3 mb-1'>
        <Card.Body className='pt-2'>
          <Form.Group className='mt-2 mb-0'>
            <Form.Check
              inline
              custom
              //defaultChecked={empSelecionada.tipo === '1'}
              value={'0'}
              className='rb-w'
              style={{zIndex: 0}}
              type='radio'
              checked={rbNFSe === '0'}
              onChange={(e) => setRbNFSe(e.target.value)}
              label='Prefeituras'
              name='radioTipoNfse'
              id='radioNFseTipo1'
            />

            <Form.Check
              inline
              custom
              disabled={empSelecionada.tipo === '1'}
              // defaultChecked={false}
              value={'1'}
              checked={rbNFSe === '1'}
              className='rb-w ml-5'
              style={{zIndex: 0}}
              type='radio'
              onChange={(e) => setRbNFSe(e.target.value)}
              label='SEFAZ Nacional'
              name='radioTipoNfse'
              id='radioNFseTipo2'
            />
          </Form.Group>
        </Card.Body>
      </Card>
      {rbNFSe === '0' ? (
        <Card className='shadow-none border mt-3 mb-3'>
          <Card.Body className='pt-2'>
            {/* {tipos de serviço tomados prestados} */}
            <Row>
              <Col>
                <Form.Label>Tipo do serviço</Form.Label>

                <Select
                  defaultValue={{value: '1', label: 'Prestados'}}
                  options={dataSelectTipoServ}
                  className=''
                  value={tipoServico}
                  onChange={(selectedOption) => {
                    setTipoServico(selectedOption);
                    localStorage.setItem(`tipoServico${usr}`, JSON.stringify(selectedOption));
                  }}
                />
              </Col>
              <Col>
                <Form.Label>Período</Form.Label>
                <div>
                  <DateRangePicker
                    container={document.getElementsByClassName('modal-body')[0]}
                    className='w-100 text-center'
                    value={periodoNfse}
                    character=' - '
                    size='md'
                    onChange={handleChange}
                    onBlur={(e) => {
                      if (periodoNfse === null) {
                        // setPeriodoNfse(e.target.value);
                      } else if (!periodoNfse[0] || !periodoNfse[1]) {
                        setPeriodoNfse([addDays(new Date(), -15), new Date()]);
                      }
                    }}
                    showMeridian
                    placement='auto'
                    ranges={preDefinedRangesNfse}
                    locale={{
                      sunday: 'Dom',
                      monday: 'Seg',
                      tuesday: 'Ter',
                      wednesday: 'Qua',
                      thursday: 'Qui',
                      friday: 'Sex',
                      saturday: 'Sab',
                      ok: 'OK',
                      today: 'Hoje',
                      yesterday: 'Ontem',
                      hours: 'Horas',
                      minutes: 'Minutos',
                      february: 'Fevereiro',
                      feb: 'Fev',
                    }}
                    format='dd/MM/yyyy'
                    defaultCalendarValue={[addDays(new Date(), -15), new Date()]}
                    editable
                    shouldDisableDate={allowedMaxDays(31)}
                  />
                  {errorMessage && <small style={{color: 'red'}}>{errorMessage}</small>}
                </div>
              </Col>
            </Row>
            <Row className='mt-3'>
              <Col>
                <Form.Label>Inscrição municipal</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Inscrição municipal'
                  maxLength={20}
                  onKeyUp={(e) => handleIM(e)}
                  onChange={(e) => {
                    setInscricaoMunicipal(e.target.value);
                  }}
                  value={inscricaoMunicipal}
                />
              </Col>
            </Row>
            <Row className='mt-3'>
              {/* select municipios */}
              <Col>
                <Form.Label>Município</Form.Label>
                <Select
                  options={selectMunicipios}
                  className=''
                  placeholder='Selecione um município'
                  defaultValue={selectMunicipios[0]}
                  value={selectedMunicipio}
                  isDisabled={carregandoMunicipios}
                  onChange={(e) => {
                    localStorage.setItem(`munNfs-${usr}`, JSON.stringify(e));
                    handleChangeTipoServ(e);
                    if (empSelecionada.tipo === '1') {
                      setIsDisabledA3(true);
                    }
                    setSelectedMunicipio(e);
                  }}
                />
                {selectMunicipios.length > 0 && (
                  <>
                    <Card.Body>
                      {selectedMunicipio !== null && (
                        <p className='mt-2'>
                          <span>Prestados: {selectedMunicipio.label !== 'Todos os municípios' ? selectedMunicipio.servicoPrestado === 'Sim' ? <span className='text-success'>SIM</span> : <span className='text-danger'>NÃO</span> : ' - '}</span>
                          <span className='ml-3'>Tomados: {selectedMunicipio.label !== 'Todos os municípios' ? selectedMunicipio.servicoTomado === 'Sim' ? <span className='text-success'>SIM</span> : <span className='text-danger'>NÃO</span> : ' - '}</span>
                          <span className='ml-3'>Provedor: {selectedMunicipio.provedor !== undefined && selectedMunicipio.provedor}</span>
                        </p>
                      )}
                      {selectedMunicipio.observacao !== undefined && selectedMunicipio.observacao.length > 0 && (
                        <>
                          <Card className='shadow-none border bg-c-yellow'>
                            <Card.Header className='py-1'>
                              <Card.Title className='mb-0 pb-1'>
                                <span className='mdi mdi-alert f-34 text-white'></span>
                              </Card.Title>
                            </Card.Header>
                            <Card.Body className='pt-1 bg-white'>
                              <p className='mt-3'>{selectedMunicipio.observacao}</p>

                              {selectedMunicipio.usuario === 'Sim' && (
                                <Form.Group className='mb-0'>
                                  <hr className='mt-3 mb-2' />
                                  <Form.Label className='mb-0'>Usuário do portal do município</Form.Label>
                                  <Form.Control
                                    type='text'
                                    placeholder='Usuário'
                                    id='txtUsuarioMunicipio'
                                  />
                                </Form.Group>
                              )}
                              {selectedMunicipio.senha === 'Sim' && (
                                <Form.Group className='mt-2 mb-0'>
                                  <Form.Label className='mb-0'>Senha do portal do município</Form.Label>
                                  <Form.Control
                                    type='password'
                                    placeholder='Senha'
                                    id='txtSenhaMunicipio'
                                  />
                                </Form.Group>
                              )}

                              {selectMunicipios.usuario === 'Sim' ||
                                (selectedMunicipio.senha === 'Sim' && (
                                  <Button
                                    className='mt-3 float-right rounded'
                                    variant='primary'
                                    size='sm'
                                    onClick={() => handleSalvarUsuarioSenha()}
                                  >
                                    Salvar
                                  </Button>
                                ))}
                            </Card.Body>
                          </Card>
                        </>
                      )}
                    </Card.Body>
                  </>
                )}
              </Col>
            </Row>
            {empSelecionada.tipo === '1' && <small className='align-bottom mb-0 text-danger mt-3'>Alguns municípios não estão disponíveis para empresas cadastradas com certificado A3.</small>}
          </Card.Body>
        </Card>
      ) : rbNFSe === '1' ? (
        <>
          <Card className='border mb-2'>
            <Card.Body className='pt-2'>
              <strong>NSU</strong>

              <Form.Group className='mt-2 mb-0'>
                <Form.Check
                  inline
                  custom
                  defaultChecked={true}
                  value={0}
                  className='rb-w w-100'
                  style={{zIndex: 0}}
                  type='radio'
                  onChange={(e) => setRbNsuNfse(e.target.value)}
                  label={`Último NSU${empSelecionada.maior_nsu_nfse !== null ? ': ' + empSelecionada.maior_nsu_nfse : ''} (recomendado) `}
                  name='radioNsuNfse'
                  id='radioNSUNfse1'
                />
                <Form.Check
                  inline
                  custom
                  defaultChecked={false}
                  value={1}
                  className='rb-w w-100'
                  style={{zIndex: 0}}
                  type='radio'
                  onChange={(e) => {
                    if (e.target.checked === true) {
                      setTimeout(() => {
                        const txt = document.getElementById('txtNsuNfse');
                        txt.focus();
                      }, 300);
                    }
                    setRbNsuNfse(e.target.value);
                  }}
                  label='A partir do NSU'
                  name='radioNsuNfse'
                  id='radioNSUNfse2'
                />
                {rbNsuNfse === '1' && (
                  <Form.Control
                    type='text'
                    placeholder='Digite o NSU'
                    min={10}
                    onKeyUp={handleKeyUpNSUNfse}
                    value={txtNsuValueNfse}
                    id='txtNsuNfse'
                    onChange={(e) => setTxtNsuValueNfse(e.target.value)}
                    className='mt-3 col-6'
                    style={{zIndex: 0}}
                  />
                )}
              </Form.Group>
            </Card.Body>
          </Card>
        </>
      ) : (
        ''
      )}
    </>
  );
};

export default OpcoesNfse;
