import AxiosMockAdapter from 'axios-mock-adapter';
import axios from './axios';
import {RestAcessoPost, RestAcessoGet} from './acesso/index-f';
import {RestEmpresasGet, RestEmpresasPost} from './empresas/index-f';
import {RestUsersGet, RestUsersPost} from './usuarios/index-f';
import {RestConsultaPost} from './consultas/index-f';
import {RestHistoryNfePost, RestHistoryNfeGet} from './historyNfe/index-f';
import {RestHistoryCtePost, RestHistoryCteGet} from './historyCte/index-f';
import {RestHistoryNfsePost, RestHistoryNfseGet} from './historyNfse';
import {RestHistoryNfcePost, RestHistoryNfceGet} from './historyNfce';
import {RestHistorySatPost, RestHistorySatGet} from './historySat';
import {RestPermGet} from './permissoes/index-f';
import {RestDashGet, RestDashPost} from './dashboard/';
import {RestAplicativosGet, RestAplicativosPost} from './aplicativos';

import {RestDataInitPost, RestDataInitGet} from './initDados/index-f';
import {RestRelatoriosGet, RestRelatoriosPost} from './relatorios/index-f';

// import {RestRelatoriosGet, RestRelatoriosPost} from './relatorios/';
import RestFaturasGet from './faturas';
import { RestPlanosGet, RestPlanosPost } from './meuPlano';

const services = new AxiosMockAdapter(axios, {delayResponse: 0});
/*
services.onGet('/api/empresas/trocaPrimeiraEmpA1').reply(async (config) => {
    return new Promise(function (resolve, reject) {
        try {
            const usersRt = base64_decode(sessionStorage.getItem(`ddesp`));
            const storage = JSON.parse(usersRt);
            const emp = storage[0];

            $.ajax({
                url: 'http://192.168.0.33/src/api/controller/empresas_controller.class.php',
                type: 'GET',
                dataType: 'json',
                data: {
                    act: 'trocaPrimeiraEmpresa',
                    key: emp
                },
                success: async function (data, status, xhr) {

                    storage[11][0] = { 'primeiroAcesso': false, 'possuiEmpresas': false };
                    const cript = base64_encode(JSON.stringify(storage));
                    localStorage.setItem("ddesp", cript);
                    
                    window.location.href = '/dashboard/tipoTeste';
                    //reject ([400, { message: 'Dados não localizados' }]);
                    resolve([200]);

                },
                error: function (xhr, status, error) {
                    
                }

            });

        } catch (err) {
            reject([500, { message: 'Server Error' }]);
        }
    });
});*/

// ============================================================= //DataInicial
services.onPost('/api/initData/').reply(async (config) => {
  return new Promise(function (resolve, reject) {
    try {
      const ret = RestDataInitPost(config);
      resolve(ret);
    } catch (err) {
      reject([500, {message: err}]);
    }
  });
});

services.onGet('/api/initData/').reply(async (config) => {
  return new Promise(function (resolve, reject) {
    try {
      const ret = RestDataInitGet(config);
      resolve(ret);
    } catch (err) {
      reject([500, {message: err}]);
    }
  });
});

// =========================================================== //account
services.onPost('/api/account/').reply(async (config) => {
  return new Promise(function (resolve, reject) {
    try {
      const ret = RestAcessoPost(config);
      resolve(ret);
    } catch (err) {
      reject([500, {message: err}]);
    }
  });
});

services.onGet('/api/account/').reply(async (config) => {
  return new Promise(function (resolve, reject) {
    try {
      const ret = RestAcessoGet(config);
      resolve(ret);
    } catch (err) {
      reject([500, {message: err}]);
    }
  });
});

// ============================================================= //dashboard
services.onGet('/api/dashboard/').reply(async (config) => {
  return new Promise(function (resolve, reject) {
    try {
      const ret = RestDashGet(config);
      resolve(ret);
    } catch (err) {
      reject([500, {message: err}]);
    }
  });
});

services.onPost('/api/dashboard/').reply(async (config) => {
  return new Promise(function (resolve, reject) {
    try {
      const ret = RestDashPost(config);
      resolve(ret);
    } catch (err) {
      reject([500, {message: err}]);
    }
  });
});

// ============================================================= //users
services.onPost('/api/users/').reply(async (config) => {
  return new Promise(function (resolve, reject) {
    try {
      const ret = RestUsersPost(config);
      resolve(ret);
    } catch (err) {
      reject([500, {message: err}]);
    }
  });
});

services.onGet('/api/users/').reply(async (config) => {
  return new Promise(function (resolve, reject) {
    try {
      const ret = RestUsersGet(config);
      resolve(ret);
    } catch (err) {
      reject([500, {message: err}]);
    }
  });
});

// ============================================================= //consultas
services.onPost('/api/consultas/').reply(async (config) => {
  return new Promise(function (resolve, reject) {
    try {
      const ret = RestConsultaPost(config);
      resolve(ret);
    } catch {
      reject([500, {message: 'Server Error'}]);
    }
  });
});

// ============================================================= //empresas
services.onGet('/api/empresas/').reply(async (config) => {
  return new Promise(function (resolve, reject) {
    try {
      const ret = RestEmpresasGet(config);
      resolve(ret);
    } catch (err) {
      reject([500, {message: err}]);
    }
  });
});

services.onPost('/api/empresas/').reply(async (config) => {
  return new Promise(function (resolve, reject) {
    try {
      const ret = RestEmpresasPost(config);
      resolve(ret);
    } catch {
      reject([500, {message: 'Server Error'}]);
    }
  });
});

// ============================================================= //history NF-e
services.onPost('/api/historyNfe/').reply(async (config) => {
  return new Promise(function (resolve, reject) {
    try {
      const ret = RestHistoryNfePost(config);
      resolve(ret);
    } catch (err) {
      reject([500, {message: err}]);
    }
  });
});
services.onGet('/api/historyNfe/').reply(async (config) => {
  return new Promise(function (resolve, reject) {
    try {
      const ret = RestHistoryNfeGet(config);
      resolve(ret);
    } catch (err) {
      reject([500, {message: err}]);
    }
  });
});

// ============================================================= //history NF-e
services.onPost('/api/historyNfce/').reply(async (config) => {
  return new Promise(function (resolve, reject) {
    try {
      const ret = RestHistoryNfcePost(config);
      resolve(ret);
    } catch (err) {
      reject([500, {message: err}]);
    }
  });
});
services.onGet('/api/historyNfce/').reply(async (config) => {
  return new Promise(function (resolve, reject) {
    try {
      const ret = RestHistoryNfceGet(config);
      resolve(ret);
    } catch (err) {
      reject([500, {message: err}]);
    }
  });
});
// ============================================================= //history NF-e
services.onPost('/api/historyNfse/').reply(async (config) => {
  return new Promise(function (resolve, reject) {
    try {
      const ret = RestHistoryNfsePost(config);
      resolve(ret);
    } catch (err) {
      reject([500, {message: err}]);
    }
  });
});
services.onGet('/api/historyNfse/').reply(async (config) => {
  return new Promise(function (resolve, reject) {
    try {
      const ret = RestHistoryNfseGet(config);
      resolve(ret);
    } catch (err) {
      reject([500, {message: err}]);
    }
  });
});

// ============================================================= //history CT-e
services.onPost('/api/historyCte/').reply(async (config) => {
  return new Promise(function (resolve, reject) {
    try {
      const ret = RestHistoryCtePost(config);
      resolve(ret);
    } catch (err) {
      reject([500, {message: err}]);
    }
  });
});
services.onGet('/api/historyCte/').reply(async (config) => {
  return new Promise(function (resolve, reject) {
    try {
      const ret = RestHistoryCteGet(config);
      resolve(ret);
    } catch (err) {
      reject([500, {message: err}]);
    }
  });
});
// ============================================================= //history SAT-e
services.onPost('/api/historySat/').reply(async (config) => {
  return new Promise(function (resolve, reject) {
    try {
      const ret = RestHistorySatPost(config);
      resolve(ret);
    } catch (err) {
      reject([500, {message: err}]);
    }
  });
});
services.onGet('/api/historySat/').reply(async (config) => {
  return new Promise(function (resolve, reject) {
    try {
      const ret = RestHistorySatGet(config);
      resolve(ret);
    } catch (err) {
      reject([500, {message: err}]);
    }
  });
});

// ============================================================= //history CT-e
services.onPost('/api/relatorio/').reply(async (config) => {
  return new Promise(function (resolve, reject) {
    try {
      const ret = RestRelatoriosPost(config);
      resolve(ret);
    } catch (err) {
      reject([500, {message: err}]);
    }
  });
});
services.onGet('/api/relatorio/').reply(async (config) => {
  return new Promise(function (resolve, reject) {
    try {
      const ret = RestRelatoriosGet(config);
      resolve(ret);
    } catch (err) {
      reject([500, {message: err}]);
    }
  });
});

// ============================================================= //permissoes
services.onGet('/api/perm/').reply(async (config) => {
  return new Promise(function (resolve, reject) {
    try {
      const ret = RestPermGet(config);
      resolve(ret);
    } catch (err) {
      reject([500, {message: err}]);
    }
  });
});

// ============================================================= //loja aplicativos
services.onPost('/api/aplicativos/').reply(async (config) => {
  return new Promise(function (resolve, reject) {
    try {
      const ret = RestAplicativosPost(config);
      resolve(ret);
    } catch (err) {
      reject([500, {message: err}]);
    }
  });
});

services.onGet('/api/aplicativos/').reply(async (config) => {
  return new Promise(function (resolve, reject) {
    try {
      const ret = RestAplicativosGet(config);
      resolve(ret);
    } catch (err) {
      reject([500, {message: err}]);
    }
  });
});

// ============================================================= //faturas
services.onGet('/api/faturas/').reply(async (config) => {
  return new Promise(function (resolve, reject) {
    try {
      const ret = RestFaturasGet(config);
      resolve(ret);
    } catch (err) {
      reject([500, {message: err}]);
    }
  });
});

// ============================================================= //planos
services.onPost('/api/meuPlano/').reply(async (config) => {
  return new Promise(function (resolve, reject) {
    try {
      const ret = RestPlanosPost(config);
      resolve(ret);
    } catch (err) {
      reject([500, {message: err}]);
    }
  });
});

services.onGet('/api/meuPlano/').reply(async (config) => {
  return new Promise(function (resolve, reject) {
    try {
      const ret = RestPlanosGet(config);
      resolve(ret);
    } catch (err) {
      reject([500, {message: err}]);
    }
  });
});



