import $ from 'jquery';
import {ENDERECO_API} from '../../config/constant';
import {decode as base64_decode, encode as base64_encode} from 'base-64';
import Swal from 'sweetalert2';

// const usersRt = sessionStorage.getItem('ddesp');
// const users = usersRt !== null ? JSON.parse(base64_decode(usersRt)) : null;

const RestEmpresasPost = (config) => {
  const usersRt = sessionStorage.getItem('ddesp');
  const users = usersRt !== null ? JSON.parse(base64_decode(usersRt)) : null;
  const emp = users[0];
  const uid = users[3];
  const Arr_ = JSON.parse(config.data);
  const caminho = Arr_.dest.trim();

  switch (caminho) {
    case 'cadastroA3':
      return new Promise(function (resolve, reject) {
        try {
          const {dataPost} = JSON.parse(config.data);
          const dataPost_ = [base64_encode(emp), base64_encode(uid), base64_encode(JSON.stringify(dataPost))];
          $.ajax({
            url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
            type: 'POST',
            dataType: 'json',
            data: {
              pp: base64_encode('emp'),
              ll: base64_encode('cadEmpA3'),
              dd: base64_encode(JSON.stringify(dataPost_)),
            },
            success: async function (data, status, xhr) {
              data = base64_decode(data);
              resolve([200, {message: data}]);
            },
            error: function (xhr, status, error) {
              reject([400, {message: 'Dados não localizados'}]);
            },
          });
        } catch (err) {
          reject([500, {message: 'Server Error'}]);
        }
      });
    case 'cadEmpresa':
      return new Promise(function (resolve, reject) {
        try {
          const {dataPost} = JSON.parse(config.data);
          const dataPost_ = [base64_encode(emp), base64_encode(uid), base64_encode(JSON.stringify(dataPost))];
          $.ajax({
            url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
            type: 'POST',
            dataType: 'json',
            data: {
              pp: base64_encode('emp'),
              ll: base64_encode('cadEmpresa'),
              dd: base64_encode(JSON.stringify(dataPost_)),
            },
            success: async function (data, status, xhr) {
              data = base64_decode(data);
              //reject ([400, { message: 'Dados não localizados' }]);
              resolve([200, {data}]);
            },
            error: function (xhr, status, error) {
              reject([400, {message: 'Dados não localizados'}]);
            },
          });
        } catch (err) {
          reject([500, {message: 'Server Error'}]);
        }
      });
    case 'deleteEmpresa':
      return new Promise(function (resolve, reject) {
        try {
          const {dataPost} = JSON.parse(config.data);
          const usersRt = base64_decode(sessionStorage.getItem('ddesp'));
          const storage = JSON.parse(usersRt);
          const emp = storage[0];
          const uid = storage[3];

          const dataPost_ = [base64_encode(emp), base64_encode(uid), base64_encode(JSON.stringify(dataPost))];
          $.ajax({
            url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
            type: 'POST',
            dataType: 'json',
            data: {
              pp: base64_encode('emp'),
              ll: base64_encode('deleteEmpresa'),
              dd: base64_encode(JSON.stringify(dataPost_)),
            },
            success: async function (data, status, xhr) {
              data = base64_decode(data);
              //reject ([400, { message: 'Dados não localizados' }]);
              resolve([200, data]);
            },
            error: function (xhr, status, error) {
              reject([400, {message: 'Dados não localizados'}]);
            },
          });
        } catch (err) {
          reject([500, {message: 'Server Error'}]);
        }
      });
    case 'deleteEmpresaComDados':
      return new Promise(function (resolve, reject) {
        try {
          const {dataPost} = JSON.parse(config.data);
          const dataPost_ = [base64_encode(emp), base64_encode(uid), base64_encode(JSON.stringify(dataPost))];
          $.ajax({
            url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
            type: 'POST',
            dataType: 'json',
            data: {
              pp: base64_encode('emp'),
              ll: base64_encode('deleteEmpresaComDados'),
              dd: base64_encode(JSON.stringify(dataPost_)),
            },
            success: async function (data, status, xhr) {
              data = base64_decode(data);
              resolve([200, data]);
            },
            error: function (xhr, status, error) {
              reject([400, {message: 'Dados não localizados'}]);
            },
          });
        } catch (err) {
          reject([500, {message: 'Server Error'}]);
        }
      });
    case 'editEmpresa':
      return new Promise(function (resolve, reject) {
        try {
          const {dataPost} = JSON.parse(config.data);
          const dataPost_ = [base64_encode(emp), base64_encode(uid), base64_encode(JSON.stringify(dataPost))];
          $.ajax({
            url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
            type: 'POST',
            dataType: 'json',
            data: {
              pp: base64_encode('emp'),
              ll: base64_encode('editEmpresa'),
              dd: base64_encode(JSON.stringify(dataPost_)),
            },
            success: async function (data, status, xhr) {
              data = base64_decode(data);
              //reject ([400, { message: 'Dados não localizados' }]);
              resolve([200, data]);
            },
            error: function (xhr, status, error) {
              reject([400, {message: 'Dados não localizados'}]);
            },
          });
        } catch (err) {
          reject([500, {message: 'Server Error'}]);
        }
      });
    case 'editConfigEmpresa':
      return new Promise(function (resolve, reject) {
        try {
          const {dataPost} = JSON.parse(config.data);
          const dataPost_ = [base64_encode(emp), base64_encode(uid), base64_encode(JSON.stringify(dataPost))];
          $.ajax({
            url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
            type: 'POST',
            dataType: 'json',
            data: {
              pp: base64_encode('emp'),
              ll: base64_encode('editConfigEmpresa'),
              dd: base64_encode(JSON.stringify(dataPost_)),
            },
            success: async function (data, status, xhr) {
              data = base64_decode(data);
              //reject ([400, { message: 'Dados não localizados' }]);
              resolve([200, data]);
            },
            error: function (xhr, status, error) {
              reject([400, {message: 'Dados não localizados'}]);
            },
          });
        } catch (err) {
          reject([500, {message: 'Server Error'}]);
        }
      });
    case 'deleteCert':
      return new Promise(function (resolve, reject) {
        try {
          const {dataPost} = JSON.parse(config.data);
          const dataPost_ = [base64_encode(emp), base64_encode(uid), base64_encode(JSON.stringify(dataPost))];
          $.ajax({
            url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
            type: 'POST',
            dataType: 'json',
            data: {
              pp: base64_encode('emp'),
              ll: base64_encode('deleteCert'),
              dd: base64_encode(JSON.stringify(dataPost_)),
            },
            success: async function (data, status, xhr) {
              data = base64_decode(data);

              //reject ([400, { message: 'Dados não localizados' }]);
              resolve([200, data]);
            },
            error: function (xhr, status, error) {
              reject([400, {message: 'Dados não localizados'}]);
            },
          });
        } catch (err) {
          reject([500, {message: 'Server Error'}]);
        }
      });
    case 'salvaCert':
      return new Promise(function (resolve, reject) {
        try {
          const {dataPost} = JSON.parse(config.data);
          const dataPost_ = [base64_encode(emp), base64_encode(uid), base64_encode(JSON.stringify(dataPost))];
          $.ajax({
            url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
            type: 'POST',
            dataType: 'json',
            data: {
              pp: base64_encode('emp'),
              ll: base64_encode('salvaCert'),
              dd: base64_encode(JSON.stringify(dataPost_)),
            },
            success: async function (data, status, xhr) {
              data = base64_decode(data);
              //reject ([400, { message: 'Dados não localizados' }]);
              resolve([200, data]);
            },
            error: function (xhr, status, error) {
              reject([400, {message: 'Dados não localizados'}]);
            },
          });
        } catch (err) {
          reject([500, {message: 'Server Error'}]);
        }
      });
    case 'salvaCertA3':
      return new Promise(function (resolve, reject) {
        try {
          const {dataPost} = JSON.parse(config.data);
          const dataPost_ = [base64_encode(emp), base64_encode(uid), base64_encode(JSON.stringify(dataPost))];
          $.ajax({
            url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
            type: 'POST',
            dataType: 'json',
            data: {
              pp: base64_encode('emp'),
              ll: base64_encode('salvaCertA3'),
              dd: base64_encode(JSON.stringify(dataPost_)),
            },
            success: async function (data, status, xhr) {
              data = base64_decode(data);
              //reject ([400, { message: 'Dados não localizados' }]);
              resolve([200, data]);
            },
            error: function (xhr, status, error) {
              reject([400, {message: 'Dados não localizados'}]);
            },
          });
        } catch (err) {
          reject([500, {message: 'Server Error'}]);
        }
      });
    case 'setFicticios':
      return new Promise(function (resolve, reject) {
        try {
          const dataPost = [base64_encode(emp), base64_encode(uid)];
          $.ajax({
            url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
            type: 'POST',
            dataType: 'json',
            data: {
              pp: base64_encode('emp'),
              ll: base64_encode('setFicticios'),
              dd: base64_encode(JSON.stringify(dataPost)),
            },
            success: async function (data, status, xhr) {
              data = base64_decode(data);
              //reject ([400, { message: 'Dados não localizados' }]);
              resolve([200, data]);
            },
            error: function (xhr, status, error) {
              reject([400, {message: 'Dados não localizados'}]);
            },
          });
        } catch (err) {
          reject([500, {message: 'Server Error'}]);
        }
      });
    case 'updateCertEmpresas':
      return new Promise(function (resolve, reject) {
        try {
          const {dataPost} = JSON.parse(config.data);
          const dataPost_ = [base64_encode(emp), base64_encode(uid), base64_encode(JSON.stringify(dataPost))];
          $.ajax({
            url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
            type: 'POST',
            dataType: 'json',
            data: {
              pp: base64_encode('emp'),
              ll: base64_encode('updateCertEmpresas'),
              dd: base64_encode(JSON.stringify(dataPost_)),
            },
            success: async function (data, status, xhr) {
              data = base64_decode(data);

              //reject ([400, { message: 'Dados não localizados' }]);
              resolve([200, {message: data}]);
            },
            error: function (xhr, status, error) {
              xhr = base64_decode(xhr.responseText);
              reject([400, {message: 'Dados não localizados'}]);
            },
          });
        } catch (err) {
          reject([500, {message: 'Server Error'}]);
        }
      });
    case 'cadastraAtualizaCertificado':
      return new Promise(function (resolve, reject) {
        try {
          const {dados} = JSON.parse(config.data);
          const usersRt = base64_decode(sessionStorage.getItem('ddesp'));
          const storage = JSON.parse(usersRt);
          const emp = storage[0];
          const us = storage[3];

          const dataPost = [base64_encode(emp), base64_encode(us), base64_encode(JSON.stringify(dados))];

          $.ajax({
            url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
            type: 'POST',
            dataType: 'json',
            data: {
              pp: base64_encode('emp'),
              ll: base64_encode('CadastraAtualizaCertificado'),
              dd: base64_encode(JSON.stringify(dataPost)),
            },
            success: async function (data, status, xhr) {
              data = base64_decode(data);

              resolve([200, {data}]);
            },
            error: function (xhr, status, error) {
              xhr.responseText = base64_decode(xhr.responseText);
              if (xhr.responseText === 'senha-err') {
                Swal.fire({
                  button: 'OK',
                  title: 'Ops',
                  html: 'A senha do certificado está incorreta!',
                  icon: 'info',
                  confirmButtonColor: '#4099FF',
                });
              } else if (xhr.responseText === 'vencido') {
                Swal.fire({
                  button: 'OK',
                  title: 'Ops',
                  html: 'O certificado selecionado está vencido!',
                  icon: 'info',
                  confirmButtonColor: '#4099FF',
                });
              } else if (xhr.responseText === 'existe') {
                Swal.fire({
                  title: 'Ops',
                  icon: 'info',
                  showCancelButton: false,
                  showConfirmButton: false,
                  html: 'O certificado já está cadastrado!',
                  timer: 1500,
                });
              } else {
                Swal.fire({
                  button: 'OK',
                  title: 'Ops',
                  html: 'Ocorreu um erro inesperado ao subir o certificado!<br/>Tente novamente, mais tarde!',
                  icon: 'info',
                  confirmButtonColor: '#4099FF',
                });
              }
            },
          });
        } catch (err) {
          reject([500, {message: 'Server Error'}]);
        }
      });
      break;
    default:
      return new Promise(function (resolve, reject) {
        reject([500, {message: 'Server Error'}]);
      });
  }
};

const RestEmpresasGet = (config) => {
  const usersRt = sessionStorage.getItem('ddesp');
  const users = usersRt !== null ? JSON.parse(base64_decode(usersRt)) : null;

  const caminho = config.dest.trim();
  const emp = users[0];
  const uid = users[3];

  switch (caminho) {
    case 'certsA1':
      return new Promise(function (resolve, reject) {
        
        try {
          const dataPost = [base64_encode(emp), base64_encode(uid)];
          $.ajax({
            url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
            type: 'Get',
            dataType: 'json',
            data: {
              pp: base64_encode('emp'),
              ll: base64_encode('certsA1'),
              dd: base64_encode(JSON.stringify(dataPost)),
            },
            success: async function (data, status, xhr) {
              
              data = JSON.parse(base64_decode(data));
              resolve([200, data]);
            },
            error: function (xhr, status, error) {
              reject([400, {message: 'erro'}]);
            },
          });
        } catch (err) {
          reject([500, {message: err}]);
        }
      });
    case 'getEmpresas':
      return new Promise(function (resolve, reject) {
        try {
          const tipoUser = users[7];
          const dataPost = [base64_encode(emp), base64_encode(uid), base64_encode(JSON.stringify(tipoUser))];
          $.ajax({
            url: encodeURI(`${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`),
            type: 'GET',
            dataType: 'json',
            data: {
              pp: base64_encode('emp'),
              ll: base64_encode('getEmpresas'),
              dd: base64_encode(JSON.stringify(dataPost)),
            },
            success: async function (data, status, xhr) {
              data = JSON.parse(base64_decode(data));
              resolve([200, data]);
            },
            error: function (xhr, status, error) {
              reject([400, {message: 'erro'}]);
            },
          });
        } catch (err) {
          reject([500, {message: 'Server Error'}]);
        }
      });
    case 'getEmpresasCert':
      return new Promise(function (resolve, reject) {
        try {
          const tipoUser = users[7];
          const dataPost = config.dataPost;
          dataPost.push(tipoUser);
          const dataPost_ = [base64_encode(emp), base64_encode(uid), base64_encode(JSON.stringify(dataPost))];
          $.ajax({
            url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
            type: 'GET',
            dataType: 'json',
            data: {
              pp: base64_encode('emp'),
              ll: base64_encode('getEmpresasCert'),
              dd: base64_encode(JSON.stringify(dataPost_)),
            },
            success: async function (data, status, xhr) {
              data = JSON.parse(base64_decode(data));
              resolve([200, data]);
            },
            error: function (xhr, status, error) {
              reject([400, {message: 'erro'}]);
            },
          });
        } catch (err) {
          reject([500, {message: 'Server Error'}]);
        }
      });
    case 'primeiraConsulta':
      return new Promise(function (resolve, reject) {
        try {
          const dataPost = [base64_encode(emp), base64_encode(uid)];
          $.ajax({
            url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
            type: 'GET',
            dataType: 'json',
            data: {
              pp: base64_encode('emp'),
              act: base64_encode('primeiraConsulta'),
              dd: base64_encode(JSON.stringify(dataPost)),
            },
            success: async function (data, status, xhr) {
              data = JSON.parse(base64_decode(data));
              resolve([200, data]);
            },
            error: function (xhr, status, error) {
              reject([400, {message: 'erro'}]);
            },
          });
        } catch (err) {
          reject([500, {message: 'Server Error'}]);
        }
      });
    case 'tableCerts':
      return new Promise(function (resolve, reject) {
        try {
          const tipoUser = users[7];
          const dataPost = [base64_encode(emp), base64_encode(uid), base64_encode(tipoUser)];

          $.ajax({
            url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
            type: 'GET',
            dataType: 'json',
            data: {
              pp: base64_encode('emp'),
              ll: base64_encode('tableCerts'),
              dd: base64_encode(JSON.stringify(dataPost)),
            },
            success: async function (data, status, xhr) {
              data = JSON.parse(base64_decode(data));
              resolve([200, data]);
            },
            error: function (xhr, status, error) {
              reject([400, {message: 'erro'}]);
            },
          });
        } catch (err) {
          reject([500, {message: 'Server Error'}]);
        }
      });
    case 'TrocaPrimeiraEmpresa':
      return new Promise(function (resolve, reject) {
        try {
          const usersRt = base64_decode(sessionStorage.getItem('ddesp'));
          const storage = JSON.parse(usersRt);

          const dataPost_ = [base64_encode(emp), base64_encode(uid)];

          $.ajax({
            url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
            type: 'GET',
            dataType: 'json',
            data: {
              pp: base64_encode('emp'),
              ll: 'trocaPrimeiraEmpresa',
              dd: base64_encode(JSON.stringify(dataPost_)),
            },
            success: async function (data, status, xhr) {
              data = JSON.parse(base64_decode(data));
              storage[11][0] = {primeiroAcesso: false, possuiEmpresas: false};
              const cript = base64_encode(JSON.stringify(storage));
              localStorage.setItem('ddesp', cript);

              window.location.href = '/inicio/tipoTeste';
              //reject ([400, { message: 'Dados não localizados' }]);
              resolve([200]);
            },
            error: function (xhr, status, error) {},
          });
        } catch (err) {
          reject([500, {message: 'Server Error'}]);
        }
      });
    case 'vrCerts':
      return new Promise(function (resolve, reject) {
        try {
          const dataPost = [base64_encode(emp), base64_encode(uid)];
          $.ajax({
            url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
            type: 'GET',
            dataType: 'json',
            data: {
              pp: base64_encode('emp'),
              ll: base64_encode('vrCerts'),
              dd: base64_encode(JSON.stringify(dataPost)),
            },
            success: async function (data, status, xhr) {
              data = base64_decode(data);
              resolve([200, data]);
            },
            error: function (xhr, status, error) {
              reject([400, {message: 'erro'}]);
            },
          });
        } catch (err) {
          reject([500, {message: 'Server Error'}]);
        }
      });
  }
};

export {RestEmpresasPost, RestEmpresasGet};
