import $ from 'jquery';
import {ENDERECO_API} from '../../config/constant';
import {decode as base64_decode, encode as base64_encode} from 'base-64';

const RestConsultaPost = (config) => {
  const usersRt = base64_decode(sessionStorage.getItem(`ddesp`));
  const storage = JSON.parse(usersRt);
  const emp = storage[0];
  const uid = storage[3];

  const Arr_ = JSON.parse(config.data);
  const caminho = Arr_.dest.trim();
  if (caminho === 'espionarA1') {
    // Espionar NF-e do tipo A1 na SEFAZ
    return new Promise(function (resolve, reject) {
      try {
        const {dataPost} = JSON.parse(config.data);
        const cnpj = dataPost[4];
        const dataPost_ = [base64_encode(emp), base64_encode(uid), base64_encode(JSON.stringify(dataPost)), base64_encode(cnpj)];

        $.ajax({
          url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
          type: 'POST',
          dataType: 'json',
          data: {
            pp: base64_encode('consultaNfe'),
            ll: base64_encode('consultaA1'),
            dd: base64_encode(JSON.stringify(dataPost_)),
          },
          success: async function (data, status, xhr) {
            data = JSON.parse(base64_decode(data));
            //reject ([400, { message: 'Dados não localizados' }]);
            resolve([200, data]);
          },
          error: function (xhr, status, error) {
            reject([400, {message: 'Dados não localizados'}]);
          },
        });
      } catch (err) {
        reject([500, {message: 'Server Error'}]);
      }
    });
  } else if (caminho === 'espionarCteA1') {
    // Espionar CT-e do tipo A1 na SEFAZ

    return new Promise(function (resolve, reject) {
      try {
        const {dataPost} = JSON.parse(config.data);

        const cnpj = dataPost[4];

        const dataPost_ = [base64_encode(emp), base64_encode(uid), base64_encode(JSON.stringify(dataPost)), base64_encode(cnpj)];

        $.ajax({
          url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
          type: 'POST',
          dataType: 'json',
          data: {
            pp: base64_encode('consultaCte'),
            ll: base64_encode('consultaCteA1'),
            dd: base64_encode(JSON.stringify(dataPost_)),
          },
          success: async function (data, status, xhr) {
            data = JSON.parse(base64_decode(data));
            //reject ([400, { message: 'Dados não localizados' }]);
            resolve([200, data]);
          },
          error: function (xhr, status, error) {
            reject([400, {message: 'Dados não localizados'}]);
          },
        });
      } catch (err) {
        reject([500, {message: 'Server Error'}]);
      }
    });
  } else if (caminho === 'espionarNfseNaA1') {
    // Espionar CT-e do tipo A1 na SEFAZ

    return new Promise(function (resolve, reject) {
      try {
        const {dataPost} = JSON.parse(config.data);
        dataPost.push(storage[7]);
        const cnpj = dataPost[1];
        const dataPost_ = [base64_encode(emp), base64_encode(uid), base64_encode(JSON.stringify(dataPost)), base64_encode(cnpj)];

        $.ajax({
          url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
          type: 'POST',
          dataType: 'json',
          data: {
            pp: base64_encode('consultaNfse'),
            ll: base64_encode('consultaNFSEna'),
            dd: base64_encode(JSON.stringify(dataPost_)),
          },
          success: async function (data, status, xhr) {
            data = JSON.parse(base64_decode(data));
            //reject ([400, { message: 'Dados não localizados' }]);
            resolve([200, data]);
          },
          error: function (xhr, status, error) {
            reject([400, {message: 'Dados não localizados'}]);
          },
        });
      } catch (err) {
        reject([500, {message: 'Server Error'}]);
      }
    });
  } else if (caminho === 'migrarDados') {
    return new Promise(function (resolve, reject) {
      try {
        const usersRt = base64_decode(sessionStorage.getItem(`ddesp`));
        const storage = JSON.parse(usersRt);
        const emp = storage[0];
        const uid = storage[3];

        /* setTimeout (function() {                
                    resolve([200, { data: 'Dados não localizados' }]);
                }, 1000);*/

        $.ajax({
          url: `${ENDERECO_API}src/api/controller/historyNfe_controller.class.php`,
          type: 'GET',
          dataType: 'json',
          data: {
            act: 'migrarDados',
            key: emp,
            ukey: uid,
          },
          success: async function (data, status, xhr) {
            //reject ([400, { message: 'Dados não localizados' }]);
            resolve([200, {data: data}]);
          },
          error: function (xhr, status, error) {},
        });
      } catch (err) {
        reject([500, {message: 'Server Error'}]);
      }
    });
  }
};

export {RestConsultaPost};
