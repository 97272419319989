import React, {useContext, useEffect, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {ConfigContext} from '../../../contexts/ConfigContext';
import useWindowSize from '../../../hooks/useWindowSize';
import NavContent from './NavContent';
import {Card} from 'react-bootstrap';
import {decode as base64_decode, encode as base64_encode} from 'base-64';
//import navigation from "../../../menu-items";

const Navigation = () => {
  const configContext = useContext(ConfigContext);
  const {dispatch} = configContext;
  const {layout, layoutType, navFixedLayout, collapseMenu, rtlLayout, boxLayout, subLayout, headerFixedLayout, menuUsuario, user64} = configContext.state;
  const windowSize = useWindowSize();
  const history = useHistory();
  const storage_ = sessionStorage.getItem(`ddesp`);
  const usersRt = JSON.parse(base64_decode(sessionStorage.getItem(`ddesp`)));

  const users = usersRt;
  const usr = users[3];
  const expirou = users[29];

  const primeirosPassos = users[11][0];
  const strg = localStorage.getItem(`xpaccss${base64_encode(usr)}`);

  const arrStrg = JSON.parse(base64_decode(strg));
  let menu = users[10];
  //const possuiEmpresas = arrStrg.possuiEmpresas;
  //const primeiroAcesso = arrStrg.primeiroAcesso;
  let navigation = '';
  const location = useLocation();

  const [possuiEmpresas, setPossuiEmpresas] = useState(primeirosPassos.possuiEmpresas);
  const [primeiroAcesso, setPrimeiroAcesso] = useState(primeirosPassos.primeiroAcesso);

  // if (possuiEmpresas === false || primeiroAcesso === true) {
  //   menu[0] = {items: []};
  // }

  useEffect(() => {
    const storage_ = sessionStorage.getItem(`ddesp`);
    if (storage_ !== null) {
      const usersRt = JSON.parse(base64_decode(sessionStorage.getItem(`ddesp`)));
      const users = usersRt;
      const PrimeirosPassos = users[11][0];

      if (expirou === true) {
        history.push('/planos');
      }

      setPossuiEmpresas(PrimeirosPassos.possuiEmpresas);
    }
  }, [configContext.state.possuiEmpresas, configContext.state.primeiroAcesso]);

  if (menuUsuario.length === 0) {
    navigation = menu[0];
  } else {
    navigation = menuUsuario; //menu[0];
  }

  //
  //   const navigation = menuUsuario; //menu[0];
  const scroll = () => {
    if (navFixedLayout && headerFixedLayout === false) {
      const main = document.querySelector('.pcoded-navbar');
      const el = document.querySelector('.pcoded-navbar.menupos-fixed');
      const scrollPosition = window.pageYOffset;

      if (scrollPosition > 60) {
        el.style.position = 'fixed';
        el.style.transition = 'none';
        el.style.marginTop = '0';
      } else {
        main.style.position = 'absolute';
        main.style.marginTop = '56px';
      }
    } else {
      document.querySelector('.pcoded-navbar').removeAttribute('style');
    }
  };

  let navClass = ['pcoded-navbar', layoutType];

  if (layout === 'horizontal') {
    navClass = [...navClass, 'theme-horizontal'];
  } else {
    if (navFixedLayout) {
      navClass = [...navClass, 'menupos-fixed'];
    }

    if (navFixedLayout && !headerFixedLayout) {
      window.addEventListener('scroll', scroll, true);
      window.scrollTo(0, 0);
    } else {
      window.removeEventListener('scroll', scroll, false);
    }
  }

  if (windowSize.width < 992 && collapseMenu) {
    navClass = [...navClass, 'mob-open'];
  } else if (collapseMenu) {
    navClass = [...navClass, 'navbar-collapsed'];
  }

  if (layoutType === 'dark') {
    document.body.classList.add('gradient-able-dark');
  } else {
    document.body.classList.remove('gradient-able-dark');
  }

  if (rtlLayout) {
    document.body.classList.add('gradient-able-rtl');
  } else {
    document.body.classList.remove('gradient-able-rtl');
  }

  if (boxLayout) {
    document.body.classList.add('container');
    document.body.classList.add('box-layout');
  } else {
    document.body.classList.remove('container');
    document.body.classList.remove('box-layout');
  }

  let navBarClass = ['navbar-wrapper'];
  if (layout === 'horizontal' && subLayout === 'horizontal-2') {
    navBarClass = [...navBarClass, 'container'];
  }
  let navContent = (
    <div className={navBarClass.join(' ')}>
      <NavContent navigation={navigation.items} />
    </div>
  );
  if (windowSize.width < 992) {
    navContent = (
      <div className='navbar-wrapper'>
        <NavContent navigation={navigation.items} />
      </div>
    );
  }

  if (possuiEmpresas === false || primeiroAcesso === true || expirou === true) {
    navContent = (
      <Card>
        <Card.Body className='text-center'>
          <i className='fas fa-question-circle f-40'></i>
          <h6 className='mt-3'>Precisa de ajuda?</h6>
          <p>Não fique com dúvidas, fale agora mesmo com um de nossos atendentes</p>
          <a
            id='btnAjudaIni'
            href='https://tawk.to/appespiaocloud'
            target='_blank'
            className='btn btn-primary btn-sm text-white m-0 text-decoration-none'
          >
            Ajuda
          </a>
        </Card.Body>
      </Card>
    );
  }
  return (
    <React.Fragment>
      <nav className={navClass.join(' ')}>{navContent}</nav>
    </React.Fragment>
  );
};

export default Navigation;
