import $ from 'jquery';
import {decode as base64_decode, encode as base64_encode} from 'base-64';
import {ENDERECO_API} from '../../config/constant';

/*
const JWT_SECRET = CONFIG.jwt.secret;
const jose = require('jose');

const verifyToken = async (uid, empid) => {
  const serviceToken = window.localStorage.getItem('serviceToken');
  const Issuer = 'urn:emid:' + base64_encode(empid);
  const Audience = 'urn:uuid:' + base64_encode(uid);

  const secret = new TextEncoder().encode(JWT_SECRET);
  const {payload} = await jose.jwtVerify(serviceToken, secret, {
    issuer: Issuer,
    audience: Audience,
  });

  return payload.userId;
};
*/
const RestPermGet = async (config) => {
  const caminho = config.dest.trim();
  const usersRt = base64_decode(sessionStorage.getItem(`ddesp`));
  const users = JSON.parse(usersRt);
  const emp = users[0];
  const uid = users[3];

  /*const verify_ = await verifyToken(uid, emp);
  const encodedString = emp + '|' + uid;
  if (verify_ === encodedString) {*/
  if (caminho === 'permissoesPagina') {
    return new Promise(function (resolve, reject) {
      try {
        //const { email } = JSON.parse(config.data);
        //
        const usersRt = base64_decode(sessionStorage.getItem(`ddesp`));
        const users = JSON.parse(usersRt);
        const emp = users[0];
        const serviceToken = window.localStorage.getItem('serviceToken');

        const dataPost = [base64_encode(emp), base64_encode(users[3]), base64_encode(JSON.stringify(config.pg))];
        $.ajax({
          url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
          type: 'GET',
          dataType: 'json',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + serviceToken,
          },
          data: {
            pp: base64_encode('perm'),
            ll: base64_encode('permissoesPagina'),
            dd: base64_encode(JSON.stringify(dataPost)),
          },
          success: async function (data, status, xhr) {
            data = JSON.parse(base64_decode(data));
            resolve([200, data]);
          },
          error: function (xhr, status, error) {
            reject([400, {message: 'Dados não localizados'}]);
          },
        });
        /*axiosN.post('http://192.168.0.33/api/users/listUsers', { 
                        empId: emp 
                    }, { headers: {
                        'Content-Type': 'application/json',
                        'type': 'application/vnd.api+json'
                    }})
                    .then(response => { 
                        resolve([200, response.data])
                    }).catch(err => {
                        
                        reject ([400, { message: 'Dados não localizados' }]);
                      });*/
      } catch (err) {
        reject([500, {message: 'Server Error'}]);
      }
    });
  }
  /*} else {
    window.location.href = '/auth/login';
  }*/
  // });
};

export {RestPermGet};
