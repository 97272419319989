import {createContext, useEffect, useReducer, useContext} from 'react';
// import jwtDecode from 'jwt-decode';

import {ACCOUNT_INITIALISE, LOGIN, LOGOUT, IMGPERFIL, PRIMEIRO_ACESSO, POSSUI_EMPRESAS, POSSUI_NOTAS, USER64} from '../store/actions';
import axios from '../services/axios';
import accountReducer from '../store/accountReducer';
import Loader from '../components/Loader/Loader';
import {ConfigContext} from './ConfigContext';
import {encode as base64_encode} from 'base-64';

const initialState = {
  isLoggedIn: false,
  isInitialised: false,
  user: null,
};

const verifyToken = (serviceToken) => {
  if (!serviceToken) {
    return false;
  }

  const decoded = wt_decode(serviceToken);
  return decoded.exp > Date.now() / 1000;
};

const wt_decode = (token) => {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace('-', '+').replace('_', '/');
  return JSON.parse(window.atob(base64));
};

const setSession = (serviceToken) => {
  if (serviceToken) {
    localStorage.setItem('serviceToken', serviceToken);
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
  } else {
    localStorage.removeItem('serviceToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

const LoginContext = createContext({
  ...initialState,
  login: () => Promise.resolve(),
  loginVariasEmpresas: () => Promise.resolve(),
  logout: () => {},
});

export const LoginProvider = ({children}) => {
  const [state, dispatch] = useReducer(accountReducer, initialState);
  const configContext = useContext(ConfigContext);

  const login = async (email, password, lembrar, token, dataGoogle, boolViaGoogle) => {
    const response = await axios.post('/api/account/', {dest: 'login', email, password, lembrar, token, dataGoogle, boolViaGoogle});
    const {serviceToken, user} = response.data;

    if (response.data.message === 'ok') {
      setSession(serviceToken);
      dispatch({
        type: LOGIN,
        payload: {
          user,
        },
      });

      configContext.dispatch({type: USER64, user64: base64_encode(response.data.user.uId)});
      configContext.dispatch({type: IMGPERFIL, imgPerfil: response.data.user.imgPerfil});
      configContext.dispatch({type: PRIMEIRO_ACESSO, primeiroAcesso: response.data.primeiroAcesso});
      configContext.dispatch({type: POSSUI_EMPRESAS, possuiEmpresas: response.data.possuiEmpresas});
      configContext.dispatch({type: POSSUI_NOTAS, possuiNotas: response.data.possuiNotas});
    }
    return response.data;
  };

  const loginVariasEmpresas = async (dataPost) => {
    const response = await axios.post('/api/account/', {dest: 'acessoVarias', dataPost});
    const {serviceToken, user} = response.data;

    if (response.data.message === 'ok') {
      setSession(serviceToken);
      dispatch({
        type: LOGIN,
        payload: {
          user,
        },
      });
    }
    return response.data;
  };

  const cadastrar = async (dados) => {
    const response = await axios.post('/api/account/', {dest: 'cadastrar', dados});
    //const data = response.data;
    const {serviceToken, user} = response.data;
    if (response.data.message === 'ok') {
      setSession(serviceToken);
      dispatch({
        type: LOGIN,
        payload: {
          user,
        },
      });

      configContext.dispatch({type: USER64, user64: base64_encode(response.data.user.uId)});
      configContext.dispatch({type: IMGPERFIL, imgPerfil: response.data.user.imgPerfil});
      configContext.dispatch({type: PRIMEIRO_ACESSO, primeiroAcesso: response.data.primeiroAcesso});
      configContext.dispatch({type: POSSUI_EMPRESAS, possuiEmpresas: response.data.possuiEmpresas});
      configContext.dispatch({type: POSSUI_NOTAS, possuiNotas: response.data.possuiNotas});
    }
    return response.data;
    //return data;
  };

  const logout = () => {
    setSession(null);
    // localStorage.clear();
    sessionStorage.clear();
    dispatch({type: LOGOUT});
  };

  useEffect(() => {
    const init = async () => {
      try {
        const serviceToken = window.localStorage.getItem('serviceToken');
        if (serviceToken /*&& verifyToken(serviceToken)*/) {
          setSession(serviceToken);

          const response = await axios.get('/api/account/', {dest: 'me'});
          const {user} = response.data;
          dispatch({
            type: ACCOUNT_INITIALISE,
            payload: {
              isLoggedIn: true,
              user: user,
            },
          });
        } else {
          dispatch({
            type: ACCOUNT_INITIALISE,
            payload: {
              isLoggedIn: false,
              user: null,
            },
          });
        }
      } catch (err) {
        dispatch({
          type: ACCOUNT_INITIALISE,
          payload: {
            isLoggedIn: false,
            user: null,
          },
        });
      }
    };

    init();
  }, []);

  if (!state.isInitialised) {
    return <Loader />;
  }
  return <LoginContext.Provider value={{...state, login, loginVariasEmpresas, cadastrar, logout}}>{children}</LoginContext.Provider>;
};

export default LoginContext;
