import $ from 'jquery';
import {ENDERECO_API} from '../../config/constant';
import {decode as base64_decode, encode as base64_encode} from 'base-64';

const RestRelatoriosPost = (config) => {
  const Arr_ = JSON.parse(config.data);
  const caminho = Arr_.dest.trim();

  const usersRt = base64_decode(sessionStorage.getItem(`ddesp`));
  const storage = JSON.parse(usersRt);
  const emp = storage[0];
  const uid = storage[3];

  switch (caminho) {
    case 'deletaConfigTabela':
      return new Promise(function (resolve, reject) {
        const {dataPost} = JSON.parse(config.data);

        const dataPost_ = [base64_encode(emp), base64_encode(uid), base64_encode(JSON.stringify(dataPost))];
        try {
          $.ajax({
            url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
            type: 'Post',
            dataType: 'json',
            data: {
              pp: base64_encode('relatorios'),
              ll: base64_encode('deletaConfigTabela'),
              dd: base64_encode(JSON.stringify(dataPost_)),
              // act: 'deletaConfigTabela',
              // key: emp,
              // ukey: uid,
              // dataPost: dataPost,
            },
            success: async function (data, _status, _xhr) {
              data = base64_decode(data);
              resolve([200, data]);
            },
            error: function (xhr, status, error) {
              reject([400, {message: 'erro'}]);
            },
          });
        } catch (err) {
          reject([500, {message: err + ' Server Error'}]);
        }
      });
    case 'geraRelatorio':
      return new Promise(function (resolve, reject) {
        const {dataPost} = JSON.parse(config.data);

        const dataPost_ = [base64_encode(emp), base64_encode(uid), base64_encode(JSON.stringify(dataPost))];
        try {
          $.ajax({
            url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
            type: 'Get',
            dataType: 'json',
            data: {
              pp: base64_encode('relatorios'),
              ll: base64_encode('geraRelatorio'),
              dd: base64_encode(JSON.stringify(dataPost_)),
              // act: 'geraRelatorio',
              // key: emp,
              // ukey: uid,
              // dataPost: dataPost,
            },
            success: async function (data, status, xhr) {
              data = base64_decode(data);
              //
              resolve([200, data]);
            },
            error: function (xhr, status, error) {
              reject([400, {message: 'erro'}]);
            },
          });
        } catch (err) {
          reject([500, {message: err + ' Server Error'}]);
        }
      });
    case 'geraRelatorioCte':
      return new Promise(function (resolve, reject) {
        const {dataPost} = JSON.parse(config.data);
        const dataPost_ = [base64_encode(emp), base64_encode(uid), base64_encode(JSON.stringify(dataPost))];
        try {
          $.ajax({
            url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
            type: 'Get',
            dataType: 'json',
            data: {
              pp: base64_encode('relatorios'),
              ll: base64_encode('geraRelatorioCte'),
              dd: base64_encode(JSON.stringify(dataPost_)),
              // act: 'geraRelatorioCte',
              // key: emp,
              // ukey: uid,
              // dataPost: dataPost,
            },
            success: async function (data, status, xhr) {
              data = base64_decode(data);
              resolve([200, data]);
            },
            error: function (xhr, status, error) {
              reject([400, {message: 'erro'}]);
            },
          });
        } catch (err) {
          reject([500, {message: err + ' Server Error'}]);
        }
      });
    case 'salvaConfigTabela':
      return new Promise(function (resolve, reject) {
        const {dataPost} = JSON.parse(config.data);
        dataPost.push(storage[7]);
        const dataPost_ = [base64_encode(emp), base64_encode(uid), base64_encode(JSON.stringify(dataPost))];
        try {
          $.ajax({
            url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
            type: 'Post',
            dataType: 'json',
            data: {
              pp: base64_encode('relatorios'),
              ll: base64_encode('updateConfigTabela'),
              dd: base64_encode(JSON.stringify(dataPost_)),

              // act: 'updateConfigTabela',
              // key: emp,
              // ukey: uid,
              // dataPost: dataPost,
            },
            success: async function (data, _status, _xhr) {
              resolve([200, data]);
            },
            error: function (xhr, status, error) {
              reject([400, {message: 'erro'}]);
            },
          });
        } catch (err) {
          reject([500, {message: err + ' Server Error'}]);
        }
      });
    case 'salvaConfigTabelaCte':
      return new Promise(function (resolve, reject) {
        const {dataPost} = JSON.parse(config.data);
        const dataPost_ = [base64_encode(emp), base64_encode(uid), base64_encode(JSON.stringify(dataPost))];
        try {
          /* props: users,
                      pesq: dataPost*/

          $.ajax({
            url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
            type: 'Post',
            dataType: 'json',
            data: {
              pp: base64_encode('relatorios'),
              ll: base64_encode('updateConfigTabelaCte'),
              dd: base64_encode(JSON.stringify(dataPost_)),
              // act: 'updateConfigTabelaCte',
              // key: emp,
              // ukey: uid,
              // dataPost: dataPost,
            },
            success: async function (data, _status, _xhr) {
              resolve([200, data]);
            },
            error: function (xhr, status, error) {
              reject([400, {message: 'erro'}]);
            },
          });
        } catch (err) {
          reject([500, {message: err + ' Server Error'}]);
        }
      });
    case 'setModeloPadrao':
      return new Promise(function (resolve, reject) {
        const {dataPost} = JSON.parse(config.data);
        dataPost.push(storage[7]);
        const dataPost_ = [base64_encode(emp), base64_encode(uid), base64_encode(JSON.stringify(dataPost))];
        try {
          $.ajax({
            url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
            type: 'Post',
            dataType: 'json',
            data: {
              pp: base64_encode('relatorios'),
              ll: base64_encode('setModeloPadrao'),
              dd: base64_encode(JSON.stringify(dataPost_)),
              // act: 'setModeloPadrao',
              // key: emp,
              // ukey: uid,
              // dataPost: dataPost,
            },
            success: async function (data, _status, _xhr) {
              data = base64_decode(data);
              resolve([200, data]);
            },
            error: function (xhr, status, error) {
              reject([400, {message: 'erro'}]);
            },
          });
        } catch (err) {
          reject([500, {message: err + ' Server Error'}]);
        }
      });
  }
};

const RestRelatoriosGet = (config) => {
  const caminho = config.dest.trim();
  const usersRt = base64_decode(sessionStorage.getItem(`ddesp`));
  const storage = JSON.parse(usersRt);
  const emp = storage[0];
  const uid = storage[3];
  if (caminho === 'getConfigTableRelNfe') {
    return new Promise(function (resolve, reject) {
      const dataPost_ = [base64_encode(emp), base64_encode(uid)];
      try {
        $.ajax({
          url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
          type: 'Get',
          dataType: 'json',
          data: {
            pp: base64_encode('relatorios'),
            ll: base64_encode('getConfigTableRelNfe'),
            dd: base64_encode(JSON.stringify(dataPost_)),
            // act: 'getConfigTableRelNfe',
            // key: emp,
            // ukey: uid,
          },
          success: async function (data, status, xhr) {
            resolve([200, data]);
          },
          error: function (xhr, status, error) {
            reject([400, {message: 'erro'}]);
          },
        });
      } catch (err) {
        reject([500, {message: err}]);
      }
    });
  } else if (caminho === 'getConfigTableRelCte') {
    return new Promise(function (resolve, reject) {
      const dataPost_ = [base64_encode(emp), base64_encode(uid)];
      try {
        $.ajax({
          url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
          type: 'Get',
          dataType: 'json',
          data: {
            pp: base64_encode('relatorios'),
            ll: base64_encode('getConfigTableRelCte'),
            dd: base64_encode(JSON.stringify(dataPost_)),
            // act: 'getConfigTableRelCte',
            // key: emp,
            // ukey: uid,
          },
          success: async function (data, status, xhr) {
            resolve([200, data]);
          },
          error: function (xhr, status, error) {
            reject([400, {message: 'erro'}]);
          },
        });
      } catch (err) {
        reject([500, {message: err}]);
      }
    });
  } else if (caminho === 'getModelos') {
    //  pp: base64_encode('nfe'),
    //           ll: base64_encode('deleteMarcadores'),
    //           dd: base64_encode(JSON.stringify(dataPost_)),
    return new Promise(function (resolve, reject) {
      const {dataPost} = config;
      dataPost.push(storage[7]);

      const dataPost_ = [base64_encode(emp), base64_encode(uid), base64_encode(JSON.stringify(dataPost))];
      try {
        $.ajax({
          url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
          type: 'Get',
          dataType: 'json',
          data: {
            pp: base64_encode('relatorios'),
            ll: base64_encode('getModelos'), // 'getModelos',
            dd: base64_encode(JSON.stringify(dataPost_)),
          },
          success: async function (data, status, xhr) {
            data = JSON.parse(base64_decode(data));
            resolve([200, data]);
          },
          error: function (xhr, status, error) {
            reject([400, {message: 'erro'}]);
          },
        });
      } catch (err) {
        reject([500, {message: err}]);
      }
    });
  } else if (caminho === 'getDownloads') {
    return new Promise(function (resolve, reject) {
      const {dataPost} = config;
      dataPost.push(storage[7]);

      const dataPost_ = [base64_encode(emp), base64_encode(uid), base64_encode(JSON.stringify(dataPost))];
      try {
        $.ajax({
          url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
          type: 'Get',
          dataType: 'json',
          data: {
            pp: base64_encode('relatorios'),
            ll: base64_encode('getDownloads'), // 'getModelos',
            dd: base64_encode(JSON.stringify(dataPost_)),
          },
          success: async function (data, status, xhr) {
            resolve([200, data]);
          },
          error: function (xhr, status, error) {
            reject([400, {message: 'erro'}]);
          },
        });
      } catch (err) {
        reject([500, {message: err}]);
      }
    });
  }
};

export {RestRelatoriosGet, RestRelatoriosPost};
