import $ from 'jquery';
import {decode as base64_decode, encode as base64_encode} from 'base-64';
import {ENDERECO_API} from '../../config/constant';
/*
const JWT_SECRET = CONFIG.jwt.secret;
const jose = require('jose');

const verifyToken = async (uid, empid) => {
  const serviceToken = window.localStorage.getItem('serviceToken');
  const Issuer = 'urn:emid:' + base64_encode(empid);
  const Audience = 'urn:uuid:' + base64_encode(uid);

  const secret = new TextEncoder().encode(JWT_SECRET);
  const {payload} = await jose.jwtVerify(serviceToken, secret, {
    issuer: Issuer,
    audience: Audience,
  });

  return payload.userId;
};
*/
const RestDashPost = (config) => {
  
  const Arr_ = JSON.parse(config.data);
  const caminho = Arr_.dest.trim();
  if (caminho === 'updateDashUser') {
    return new Promise(function (resolve, reject) {
      try {
        const {dataPost} = JSON.parse(config.data);
        const usersRt = base64_decode(sessionStorage.getItem(`ddesp`));
        const storage = JSON.parse(usersRt);
        const emp = storage[0];
        const uid = storage[3];
        dataPost.push(storage[7]); //tipo do usuario master/normal
        const dataPost_ = [base64_encode(emp), base64_encode(uid), base64_encode(dataPost)];
        $.ajax({
          url: encodeURI(`${ENDERECO_API}src/api/controller/dashboard_controller.class.php`),
          type: 'POST',
          dataType: 'json',
          data: {
            ll: base64_encode('updateDashUser'),
            dd: base64_encode(dataPost_),
          },
          success: async function (data, status, xhr) {
            data = base64_decode(data);
            resolve([200, {data}]);
          },
          error: function (xhr, status, error) {
            reject([400, {message: 'Dados não localizados'}]);
          },
        });
      } catch (err) {
        reject([500, {message: 'Server Error'}]);
      }
    });
  }
};

const RestDashGet = async (config) => {
  
  const caminho = config.dest.trim();
  const usersRt = base64_decode(sessionStorage.getItem(`ddesp`));
  const users = JSON.parse(usersRt);
  const emp = users[0];
  const uid = users[3];
  const tipoUser = users[7];

  /*const verify_ = await verifyToken(uid, emp);
  const encodedString = emp + '|' + uid;
  if (verify_ === encodedString) {*/
  if (caminho === 'totaisDocumentos') {
    return new Promise(function (resolve, reject) {
      try {
        config.dataPost.push(tipoUser);
        //const { email } = JSON.parse(config.data);
        //

        const dataPost = [base64_encode(emp), base64_encode(uid), base64_encode(config.dataPost)];
        const serviceToken = window.localStorage.getItem('serviceToken');
        $.ajax({
          url: encodeURI(`${ENDERECO_API}src/api/controller/dashboard_controller.class.php`),
          type: 'GET',
          dataType: 'json',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + serviceToken,
          },
          data: {
            ll: base64_encode('totaisDocumentos'),
            dd: base64_encode(dataPost),
          },
          success: async function (data, status, xhr) {
            data = JSON.parse(base64_decode(data));
            resolve([200, data]);
          },
          error: function (xhr, status, error) {
            reject([400, {message: 'Dados não localizados'}]);
          },
        });
      } catch (err) {
        reject([500, {message: 'Server Error'}]);
      }
    });
  } else if (caminho === 'totaisSituacao') {
    return new Promise(function (resolve, reject) {
      try {
        config.dataPost.push(tipoUser);
        const dataPost = [base64_encode(emp), base64_encode(uid), base64_encode(config.dataPost)];
        const serviceToken = window.localStorage.getItem('serviceToken');

        $.ajax({
          url: encodeURI(`${ENDERECO_API}src/api/controller/dashboard_controller.class.php`),
          type: 'GET',
          dataType: 'json',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + serviceToken,
          },
          data: {
            ll: base64_encode('totaisSituacao'),
            dd: base64_encode(dataPost),
          },
          success: async function (data, status, xhr) {
            data = JSON.parse(base64_decode(data));
            resolve([200, data]);
          },
          error: function (xhr, status, error) {
            reject([400, {message: 'Dados não localizados'}]);
          },
        });
      } catch (err) {
        reject([500, {message: 'Server Error'}]);
      }
    });
  } else if (caminho === 'situacaoTable') {
    return new Promise(function (resolve, reject) {
      try {
        config.dataPost.push(tipoUser);
        const dataPost = [base64_encode(emp), base64_encode(uid), base64_encode(config.dataPost)];
        const serviceToken = window.localStorage.getItem('serviceToken');
        $.ajax({
          url: encodeURI(`${ENDERECO_API}src/api/controller/dashboard_controller.class.php`),
          type: 'GET',
          dataType: 'json',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + serviceToken,
          },
          data: {
            ll: base64_encode('situacaoTable'),
            dd: base64_encode(dataPost),
          },
          success: async function (data, status, xhr) {
            data = JSON.parse(base64_decode(data));
            resolve([200, data]);
          },
          error: function (xhr, status, error) {
            reject([400, {message: 'Dados não localizados'}]);
          },
        });
      } catch (err) {
        reject([500, {message: 'Server Error'}]);
      }
    });
  } else if (caminho === 'totaisValoresDocumento') {
    return new Promise(function (resolve, reject) {
      try {
        config.dataPost.push(tipoUser);
        const dataPost = [base64_encode(emp), base64_encode(uid), base64_encode(config.dataPost)];
        const serviceToken = window.localStorage.getItem('serviceToken');
        $.ajax({
          url: encodeURI(`${ENDERECO_API}src/api/controller/dashboard_controller.class.php`),
          type: 'GET',
          dataType: 'json',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + serviceToken,
          },
          data: {
            ll: base64_encode('totaisValoresDocumento'),
            dd: base64_encode(dataPost),
          },
          success: async function (data, status, xhr) {
            data = JSON.parse(base64_decode(data));
            resolve([200, data]);
          },
          error: function (xhr, status, error) {
            reject([400, {message: 'Dados não localizados'}]);
          },
        });
      } catch (err) {
        reject([500, {message: 'Server Error'}]);
      }
    });
  } else if (caminho === 'manifestacaoNfe') {
    return new Promise(function (resolve, reject) {
      config.dataPost.push(tipoUser);
      try {
        const dataPost = [base64_encode(emp), base64_encode(uid), base64_encode(config.dataPost)];
        const serviceToken = window.localStorage.getItem('serviceToken');
        $.ajax({
          url: encodeURI(`${ENDERECO_API}src/api/controller/dashboard_controller.class.php`),
          type: 'GET',
          dataType: 'json',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + serviceToken,
          },
          data: {
            ll: base64_encode('manifestacaoNfe'),
            dd: base64_encode(dataPost),
          },
          success: async function (data, status, xhr) {
            data = JSON.parse(base64_decode(data));
            resolve([200, data]);
          },
          error: function (xhr, status, error) {
            reject([400, {message: 'Dados não localizados'}]);
          },
        });
      } catch (err) {
        reject([500, {message: 'Server Error'}]);
      }
    });
  } else if (caminho === 'melhoresFornecedores') {
    return new Promise(function (resolve, reject) {
      try {
        config.dataPost.push(tipoUser);
        const dataPost = [base64_encode(emp), base64_encode(uid), base64_encode(config.dataPost)];
        const serviceToken = window.localStorage.getItem('serviceToken');
        $.ajax({
          url: encodeURI(`${ENDERECO_API}src/api/controller/dashboard_controller.class.php`),
          type: 'GET',
          dataType: 'json',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + serviceToken,
          },
          data: {
            ll: base64_encode('melhoresFornecedores'),
            dd: base64_encode(dataPost),
          },
          success: async function (data, status, xhr) {
            data = JSON.parse(base64_decode(data));
            resolve([200, data]);
          },
          error: function (xhr, status, error) {
            reject([400, {message: 'Dados não localizados'}]);
          },
        });
      } catch (err) {
        reject([500, {message: 'Server Error'}]);
      }
    });
  } else if (caminho === 'duplicatas') {
    return new Promise(function (resolve, reject) {
      try {
        config.dataPost.push(tipoUser);
        const dataPost = [base64_encode(emp), base64_encode(uid), base64_encode(config.dataPost)];
        const serviceToken = window.localStorage.getItem('serviceToken');
        $.ajax({
          url: encodeURI(`${ENDERECO_API}src/api/controller/dashboard_controller.class.php`),
          type: 'GET',
          dataType: 'json',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + serviceToken,
          },
          data: {
            ll: base64_encode('duplicatas'),
            dd: base64_encode(dataPost),
          },
          success: async function (data, status, xhr) {
            data = JSON.parse(base64_decode(data));
            resolve([200, data]);
          },
          error: function (xhr, status, error) {
            reject([400, {message: 'Dados não localizados'}]);
          },
        });
      } catch (err) {
        reject([500, {message: 'Server Error'}]);
      }
    });
  } else if (caminho === 'duplicatasTable') {
    return new Promise(function (resolve, reject) {
      try {
        config.dataPost.push(tipoUser);
        const dataPost = [base64_encode(emp), base64_encode(uid), base64_encode(config.dataPost)];
        const serviceToken = window.localStorage.getItem('serviceToken');
        $.ajax({
          url: encodeURI(`${ENDERECO_API}src/api/controller/dashboard_controller.class.php`),
          type: 'GET',
          dataType: 'json',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + serviceToken,
          },
          data: {
            act: base64_encode('duplicatasTable'),
            dd: base64_encode(dataPost),
          },
          success: async function (data, status, xhr) {
            resolve([200, data]);
          },
          error: function (xhr, status, error) {
            reject([400, {message: 'Dados não localizados'}]);
          },
        });
      } catch (err) {
        reject([500, {message: 'Server Error'}]);
      }
    });
  } else if (caminho === 'canceladasTable') {
    return new Promise(function (resolve, reject) {
      try {
        config.dataPost.push(tipoUser);
        const dataPost = [base64_encode(emp), base64_encode(uid), base64_encode(config.dataPost)];
        const serviceToken = window.localStorage.getItem('serviceToken');
        $.ajax({
          url: encodeURI(`${ENDERECO_API}src/api/controller/dashboard_controller.class.php`),
          type: 'GET',
          dataType: 'json',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + serviceToken,
          },
          data: {
            ll: base64_encode('canceladasTable'),
            dd: dataPost,
          },
          success: async function (data, status, xhr) {
            data = JSON.parse(base64_decode(data));
            resolve([200, data]);
          },
          error: function (xhr, status, error) {
            reject([400, {message: 'Dados não localizados'}]);
          },
        });
      } catch (err) {
        reject([500, {message: 'Server Error'}]);
      }
    });
  }
  /*} else {
    window.location.href = '/auth/login';
  }*/
  // });
};

export {RestDashGet, RestDashPost};
