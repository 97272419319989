import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { decode as base64_decode } from 'base-64';
import useAuth from '../../hooks/useAuth';
import { ConfigContext } from '../../contexts/ConfigContext';

const GuestGuard = ({ children }) => {
  const configContext = useContext(ConfigContext);
  const { isLoggedIn } = useAuth();
  if (isLoggedIn) {
    // const Pa = sessionStorage.getItem(`ddesp`);
    // const PaArr = Pa === null ? null : JSON.parse(base64_decode(Pa));

    const primeiroAcesso = configContext.state.primeiroAcesso;
    const possuiEmpresas = configContext.state.possuiEmpresas;
    const tipoTeste = configContext.state.tipoTeste;
    //const possuiNotas = PaArr === null ? false : PaArr[11][0]['possuiNotas'];
    const possuiNotas = false;

    // if (primeiroAcesso === true) {
    //   return <Redirect to='/inicio/boasVindas' />;
    // } else if (possuiEmpresas === false) {
    //   return <Redirect to='/inicio/tipoTeste' />;
    // } else {
    return <Redirect to='/dashboard' />;
    // }
  } else {
    const vrScritp = document.getElementById('gtmScript');

    if (window.location.href.indexOf('cadastro') > -1) {
      if (vrScritp == null) {

        const script = document.createElement('script');
        const noScript = document.createElement('noscript');

        script.id = 'gtmScript';
        noScript.id = 'gtmNoScript';
        script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-WHV6NR6');`;

        document.getElementsByTagName('head')[0].appendChild(script);

        noScript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-WHV6NR6"
height="0" width="0" style="display:none;visibility:hidden"></iframe>`;

        document.getElementsByTagName('body')[0].insertBefore(noScript, document.body.firstChild);
      }
    } else {
      

      document.getElementById('gtmScript') && document.getElementById('gtmScript').remove();
      document.getElementById('gtmNoScript') && document.getElementById('gtmNoScript').remove();
    }


  }

  return <React.Fragment>{children}</React.Fragment>;
};

export default GuestGuard;
